.general-form-text-field, .field-edit-window .field--name {
  margin-bottom: 10px;
  border: 1px solid #AAA;
  border-radius: 4px;
  color: #888;
  height: 26px;
  line-height: 26px;
  padding: 2px 7px;
  font-family: "Roboto";
  font-size: 14px;
  margin-top: 5px;
  box-sizing: content-box; }

.general-form-button, .f-edit-map-tools .f-edit-map-tools--save, .field-edit-window .field-edit-window--save, .field-edit-window .field-edit-window--button {
  display: block;
  font-size: 15px;
  font-family: "Roboto-Medium";
  background: rgba(0, 0, 0, 0) linear-gradient(-180deg, #a0e22a 0%, #679f38 100%) repeat scroll 0 0;
  border-radius: 4px;
  color: white;
  padding: 8px 17px;
  margin-top: 10px;
  margin-bottom: 5px; }

.no-transition {
  transition: none !important; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.animated-65 {
    animation-duration: .65s; }
  .animated.animated-30 {
    animation-duration: .3s; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

.checkbox {
  padding-left: 20px; }

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555; }

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1; }

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c"; }

.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px; }

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65; }

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7; }

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #337ab7;
  border-color: #337ab7; }

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.radio {
  padding-left: 20px; }

.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }

.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: 'FontAwesome';
  content: "\f00c"; }

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff; }

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff; }

/**
 * angular-ui-notification - Angular.js service providing simple notifications using Bootstrap 3 styles with css transitions for animating
 * @author Alex_Crack
 * @version v0.1.0
 * @link https://github.com/alexcrack/angular-ui-notification
 * @license MIT
 */
.ui-notification {
  position: fixed;
  z-index: 9999;
  width: 300px;
  cursor: pointer;
  -webkit-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  color: #fff;
  border-radius: 0;
  background: #337ab7;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); }

.ui-notification.killed {
  -webkit-transition: opacity ease 1s;
  -o-transition: opacity ease 1s;
  transition: opacity ease 1s;
  opacity: 0; }

.ui-notification > h3 {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin: 10px 10px 0 10px;
  padding: 0 0 5px 0;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.ui-notification a {
  color: #fff; }

.ui-notification a:hover {
  text-decoration: underline; }

.ui-notification > .message {
  margin: 10px 10px 10px 10px; }

.ui-notification.warning {
  color: #fff;
  background: #f0ad4e; }

.ui-notification.error {
  color: #fff;
  background: #d9534f; }

.ui-notification.success {
  color: #fff;
  background: #5cb85c; }

.ui-notification.info {
  color: #fff;
  background: #5bc0de; }

.ui-notification:hover {
  opacity: .7; }

.f-aside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001; }

.f-aside-new {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2; }
  .f-aside-new c-blackout .f-w--blackout {
    position: fixed;
    z-index: -2; }

.f-aside-menu {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 60px;
  background: #25606f;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }
  .f-aside-menu > button {
    display: block;
    width: 60px;
    height: 60px;
    word-spacing: 60px;
    margin: 10px 10px 10px 12px;
    background: #aee2f3;
    border-radius: 3px; }
    .f-aside-menu > button > img {
      display: block;
      height: 33px;
      margin: 0 auto 2px; }
    .f-aside-menu > button > span {
      display: block;
      text-align: center;
      color: #003A4A;
      font-size: 10px; }

.f-aside-w-info {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 450px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }
  .f-aside-w-info.ng-enter, .f-aside-w-info.ng-leave {
    animation-duration: .3s;
    animation-fill-mode: both; }
  .f-aside-w-info.ng-enter, .f-aside-w-info.ng-leave.ng-leave-active {
    animation-name: fadeOutLeft; }
  .f-aside-w-info.ng-leave, .f-aside-w-info.ng-enter.ng-enter-active {
    animation-name: fadeInLeft; }

.f-aside-w-info-header {
  position: relative;
  color: #073765;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid #d2d8da;
  font-weight: bold;
  background: #F9FDFE; }
  .f-aside-w-info-header > span {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: #073743;
    line-height: 19px;
    /*firefox font bold fix*/
    font-weight: 200 !important; }
  .f-aside-w-info-header .f-aside-w-info-header--close {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: url("/img/close_btn.22042016.png");
    width: 28px;
    height: 28px; }

.f-aside-w-info-body {
  padding: 20px 20px 20px 24px; }
  .f-aside-w-info-body > p {
    font-family: "Roboto-Light";
    font-size: 13px;
    color: #6B6B6B;
    line-height: 16px;
    text-align: left;
    padding-right: 20px;
    padding-left: 20px; }
  .f-aside-w-info-body .f-aside-w-info-body--display {
    margin: 15px 6px; }
    .f-aside-w-info-body .f-aside-w-info-body--display > li {
      color: #25606f;
      margin-bottom: 5px;
      border-radius: 4px;
      line-height: 30px;
      text-align: left;
      cursor: pointer;
      width: 394px;
      background: #DCDCDC; }
      .f-aside-w-info-body .f-aside-w-info-body--display > li:last-of-type {
        margin-bottom: 0; }
      .f-aside-w-info-body .f-aside-w-info-body--display > li.is-active {
        background: #cbe2e8; }
      .f-aside-w-info-body .f-aside-w-info-body--display > li > img {
        float: left;
        height: 25px;
        margin: 2px 9px 3px 3px; }
      .f-aside-w-info-body .f-aside-w-info-body--display > li > span {
        display: block;
        margin-left: 20px;
        padding-left: 10px;
        font-family: "Roboto-Light";
        font-size: 13.3px;
        color: rgba(7, 54, 66, 0.79); }

.f-aside-thresholds {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 450px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }
  .f-aside-thresholds.ng-enter, .f-aside-thresholds.ng-leave {
    animation-duration: .3s;
    animation-fill-mode: both; }
  .f-aside-thresholds.ng-enter, .f-aside-thresholds.ng-leave.ng-leave-active {
    animation-name: fadeOutLeft; }
  .f-aside-thresholds.ng-leave, .f-aside-thresholds.ng-enter.ng-enter-active {
    animation-name: fadeInLeft; }
  .f-aside-thresholds-point-edit-field {
    display: block;
    width: 100%;
    box-shadow: 0 0 5px #e89c20;
    border-radius: 3px;
    padding: 2.5px;
    line-height: 12px;
    height: 24px;
    font-size: 0.75em;
    text-align: center; }
  .f-aside-thresholds .f-aside-thresholds-units {
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 100%;
    top: 10px;
    margin-left: 65px;
    padding: 10px;
    border-radius: 10px;
    z-index: 999; }
    .f-aside-thresholds .f-aside-thresholds-units .units {
      display: flex;
      margin-right: 50px; }
    .f-aside-thresholds .f-aside-thresholds-units .title {
      color: white;
      white-space: nowrap;
      line-height: 30px;
      margin-right: 10px; }
      .f-aside-thresholds .f-aside-thresholds-units .title span {
        white-space: nowrap; }
    .f-aside-thresholds .f-aside-thresholds-units .btn-white {
      color: #000;
      background-color: #fff;
      border-radius: 0;
      border: none; }
    .f-aside-thresholds .f-aside-thresholds-units .btn-white:focus {
      outline: 0; }
    .f-aside-thresholds .f-aside-thresholds-units .active {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c; }

.f-aside-thresholds-header {
  position: relative;
  color: #073765;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid #d2d8da;
  font-weight: bold;
  background: #F9FDFE;
  padding-right: 45px;
  padding-left: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .f-aside-thresholds-header > span {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: #073743;
    line-height: 19px;
    /*firefox font bold fix*/
    font-weight: 200 !important; }
  .f-aside-thresholds-header .f-aside-thresholds-header--close {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: url("/img/close_btn.22042016.png");
    width: 28px;
    height: 28px; }

.f-aside-thresholds-body {
  padding: 0 20px 0 24px;
  height: 500px;
  height: calc(100% - 110px);
  overflow-y: scroll; }
  .f-aside-thresholds-body .f-aside-thresholds-body--display {
    margin: 15px 6px; }
    .f-aside-thresholds-body .f-aside-thresholds-body--display > li {
      color: #25606f;
      margin-bottom: 5px;
      border-radius: 4px;
      line-height: 30px;
      text-align: left;
      cursor: pointer;
      background: #e2ebee;
      padding: 0 10px;
      position: relative; }
      .f-aside-thresholds-body .f-aside-thresholds-body--display > li.active {
        background: #e89c20; }
        .f-aside-thresholds-body .f-aside-thresholds-body--display > li.active span {
          color: white; }
      .f-aside-thresholds-body .f-aside-thresholds-body--display > li:last-of-type {
        margin-bottom: 0; }
      .f-aside-thresholds-body .f-aside-thresholds-body--display > li.is-active {
        background: #cbe2e8; }
      .f-aside-thresholds-body .f-aside-thresholds-body--display > li > img {
        float: left;
        height: 25px;
        margin: 2px 9px 3px 3px; }
      .f-aside-thresholds-body .f-aside-thresholds-body--display > li > span {
        display: block;
        font-family: "Roboto-Light";
        font-size: 13.3px;
        font-weight: 800;
        color: #103e4a;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        .f-aside-thresholds-body .f-aside-thresholds-body--display > li > span .icons {
          position: absolute;
          top: 4px;
          right: 10px; }
          .f-aside-thresholds-body .f-aside-thresholds-body--display > li > span .icons .has-custom {
            height: 20px;
            width: 20px;
            position: relative;
            margin-left: 5px;
            background: url("/img/warning-icon.18122017.png");
            float: right;
            background-size: cover; }
          .f-aside-thresholds-body .f-aside-thresholds-body--display > li > span .icons .all-na {
            height: 20px;
            width: 20px;
            position: relative;
            background: url("/img/warning-icon-red.25122017.png");
            float: right;
            background-size: cover; }

.f-aside-thresholds-controls .btn {
  width: 45%; }
  .f-aside-thresholds-controls .btn:first-child {
    margin: 5% 1.25% 0 2.5%; }
  .f-aside-thresholds-controls .btn:last-child {
    margin: 5% 2.5% 0 1.25%;
    background: none;
    color: #1fab39;
    text-decoration: none; }
    .f-aside-thresholds-controls .btn:last-child:hover {
      text-decoration: underline; }
    .f-aside-thresholds-controls .btn:last-child:active {
      box-shadow: none;
      color: #2a6b7c; }

.f-aside-thresholds-graph {
  cursor: default;
  position: absolute;
  top: 80px;
  left: 459px; }
  .f-aside-thresholds-graph svg text {
    cursor: default;
    touch-callout: none;
    user-select: none; }

.f-aside-m-add-task {
  height: 100%;
  width: 450px;
  background: #f0f0f0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }
  .f-aside-m-add-task.has-report {
    width: 1440px; }
  .f-aside-m-add-task .f-aside-m-edit-task-report {
    position: absolute;
    left: 450px;
    top: 51px;
    height: 100%;
    width: 990px;
    padding-bottom: 51px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 0; }
    .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body {
      height: 100%;
      width: 100%;
      background: #D6D6D6;
      padding: 10px;
      overflow-y: scroll;
      overflow-x: scroll; }
      .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body h3 {
        margin: 20px 0 10px 0; }
      .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body .report-table {
        background: white; }
        .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body .report-table .col1 {
          background: #FADCC6; }
        .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body .report-table td {
          border: 1px solid black;
          padding: 2px; }
        .f-aside-m-add-task .f-aside-m-edit-task-report .f-aside-m-edit-task-report-body .report-table th {
          border: 1px solid black;
          font-weight: bold;
          padding: 4px; }

.f-aside-m-add-task-header {
  position: relative;
  min-height: 50px;
  border-bottom: 1px solid #d2d8da;
  background: #F9FDFE;
  color: #073765;
  text-align: center;
  line-height: 50px;
  font-weight: 700; }
  .f-aside-m-add-task-header #reportXlsxForm button {
    display: inline;
    width: 150px;
    right: 46px;
    top: 10px;
    height: 28px;
    position: absolute; }
  .f-aside-m-add-task-header > span {
    font-family: "Roboto-Medium";
    font-size: 16px;
    font-weight: 200 !important;
    color: #073743;
    line-height: 19px; }
  .f-aside-m-add-task-header--close {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: url("/img/close_btn.22042016.png");
    width: 28px;
    height: 28px; }
  .f-aside-m-add-task-header--users {
    position: absolute;
    top: 50%;
    right: 45px;
    margin-top: -15px; }
    .f-aside-m-add-task-header--users > button,
    .f-aside-m-add-task-header--users > div:first-child {
      display: block;
      width: 28px;
      height: 28px;
      background: no-repeat center;
      background-size: 40px 40px; }
    .f-aside-m-add-task-header--users .dropdown-menu {
      top: -50%;
      padding: 0;
      margin: 6px 0 0;
      border: 1px solid #bec0b7;
      box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.2), inset 0px 5px 8px -5px rgba(0, 0, 0, 0.4); }
      .f-aside-m-add-task-header--users .dropdown-menu ul li {
        text-align: left;
        background: #f8fbf4;
        cursor: pointer; }
        .f-aside-m-add-task-header--users .dropdown-menu ul li:not(.show-more):hover {
          background: #d9f0f2; }
        .f-aside-m-add-task-header--users .dropdown-menu ul li .avatar {
          display: inline-block;
          width: 45px;
          height: 45px;
          background-position: center; }
        .f-aside-m-add-task-header--users .dropdown-menu ul li.current {
          pointer-events: none;
          cursor: default;
          background-color: #c3dee5; }
          .f-aside-m-add-task-header--users .dropdown-menu ul li.current .avatar {
            border-radius: 50%; }
          .f-aside-m-add-task-header--users .dropdown-menu ul li.current:hover {
            background: #c3dee5; }
      .f-aside-m-add-task-header--users .dropdown-menu ul:not(.users-inline) {
        max-height: 300px;
        width: 220px;
        overflow: auto;
        font-size: 0; }
        .f-aside-m-add-task-header--users .dropdown-menu ul:not(.users-inline) li {
          line-height: normal; }
          .f-aside-m-add-task-header--users .dropdown-menu ul:not(.users-inline) li.show-more {
            display: none; }
          .f-aside-m-add-task-header--users .dropdown-menu ul:not(.users-inline) li .name {
            vertical-align: top;
            display: inline-block;
            width: calc(100% - 45px);
            font-size: 14px;
            line-height: 45px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
      .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline {
        white-space: nowrap;
        line-height: normal;
        font-size: 0; }
        .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline li {
          vertical-align: top;
          display: inline-block;
          width: 45px;
          height: 45px;
          line-height: 45px; }
          .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline li:nth-child(5) ~ li:not(.show-more) {
            display: none; }
          .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline li.show-more {
            cursor: auto;
            width: auto;
            font-size: 14px;
            color: #073743;
            padding: 0 8px; }
            .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline li.show-more span {
              cursor: pointer;
              vertical-align: middle;
              display: inline-block;
              padding: 1px 5px;
              background: #eef8f9;
              border: 1px solid #e4e4e4;
              border-radius: 1px;
              line-height: normal;
              font-weight: 200;
              font-size: 14px;
              color: #8D9FA5; }
          .f-aside-m-add-task-header--users .dropdown-menu ul.users-inline li .name {
            display: none; }

.f-aside-m-add-task-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 51px);
  padding: 20px 10px; }
  .f-aside-m-add-task-body .grid-tab > ul > li > a {
    padding: 4px;
    font-size: 12px; }
  .f-aside-m-add-task-body .grid-tab .tab-content {
    padding: 4px;
    margin-bottom: 10px; }
  .f-aside-m-add-task-body h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #395e68;
    font-weight: 700; }
  .f-aside-m-add-task-body .visible-md-block li:after {
    content: '';
    display: block;
    clear: both; }
  .f-aside-m-add-task-body .visible-md-block .visible-md-row {
    margin-top: 5px;
    margin-bottom: 5px; }
  .f-aside-m-add-task-body table {
    width: 100%;
    margin-bottom: 20px; }
    .f-aside-m-add-task-body table tr > td {
      width: 50%;
      padding: 5px;
      vertical-align: middle;
      border-collapse: collapse;
      border-spacing: 0; }
      .f-aside-m-add-task-body table tr > td:first-child {
        text-align: right; }
      .f-aside-m-add-task-body table tr > td label {
        color: #395e68; }
      .f-aside-m-add-task-body table tr > td .form-control-static {
        padding: 7px 0; }

.f-aside-m-edit-task-body {
  width: 450px;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 51px);
  padding: 20px 10px; }
  .f-aside-m-edit-task-body--report {
    position: absolute;
    right: 6px;
    top: 4px;
    cursor: pointer; }
  .f-aside-m-edit-task-body #insectSelect {
    margin-bottom: 15px; }
  .f-aside-m-edit-task-body h2 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #395e68; }
  .f-aside-m-edit-task-body h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    color: #395e68; }
  .f-aside-m-edit-task-body hr {
    border-color: #ccc;
    margin: 10px 0; }
  .f-aside-m-edit-task-body .sampling-title .btn1 {
    border-radius: 4px 0 0 4px;
    cursor: default;
    border: none; }
    .f-aside-m-edit-task-body .sampling-title .btn1[disabled="disabled"] {
      color: black;
      background: white; }
    .f-aside-m-edit-task-body .sampling-title .btn1:hover {
      background: #5cb85c; }
  .f-aside-m-edit-task-body .sampling-title .btn2 {
    border-radius: 0 4px 4px 0;
    cursor: default;
    border: none; }
    .f-aside-m-edit-task-body .sampling-title .btn2[disabled="disabled"] {
      color: black;
      background: white; }
    .f-aside-m-edit-task-body .sampling-title .btn2:hover {
      background: #5cb85c; }
  .f-aside-m-edit-task-body div hr:last-of-type {
    display: none; }
  .f-aside-m-edit-task-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    margin-bottom: 18px; }
    .f-aside-m-edit-task-body table > tbody > tr > td {
      border: none;
      vertical-align: middle;
      text-align: right;
      font-weight: 500; }
    .f-aside-m-edit-task-body table > tbody > tr a {
      display: block; }
      .f-aside-m-edit-task-body table > tbody > tr a img {
        display: block;
        width: 123px; }
  .f-aside-m-edit-task-body .task-time > tbody > tr > td:first-child {
    color: #485e67;
    font-size: 12px;
    padding-right: 4px; }
  .f-aside-m-edit-task-body .task-time > tbody > tr > td:last-child {
    color: #4c4c4c;
    font-size: 12px;
    text-align: left; }
  .f-aside-m-edit-task-body .task-time > tbody > tr td.end-date {
    color: #233a45; }
  .f-aside-m-edit-task-body .on-field {
    background: yellow; }
  .f-aside-m-edit-task-body .task-point {
    background: #e2ebee;
    border-radius: 3px;
    margin-bottom: 24px; }
    .f-aside-m-edit-task-body .task-point > tbody > tr > td:first-child {
      text-align: left;
      padding-left: 18px;
      font-weight: 700; }
    .f-aside-m-edit-task-body .task-point > tbody > tr > td:last-child {
      padding-right: 18px; }
    .f-aside-m-edit-task-body .task-point > tbody > tr > td span {
      font-weight: 700; }
  .f-aside-m-edit-task-body .a-property {
    font-weight: 500 !important;
    font-size: 12px; }
    .f-aside-m-edit-task-body .a-property span {
      font-weight: 500 !important; }
  .f-aside-m-edit-task-body .activity:after {
    content: '';
    display: block;
    clear: both; }
  .f-aside-m-edit-task-body .activity .result-with-image {
    display: flex; }
    .f-aside-m-edit-task-body .activity .result-with-image a {
      width: 85px;
      height: 85px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block; }
      .f-aside-m-edit-task-body .activity .result-with-image a img {
        width: 85px;
        height: 85px; }
    .f-aside-m-edit-task-body .activity .result-with-image .res-col-2 {
      width: 100%;
      margin-bottom: 10px;
      display: inline-block; }
      .f-aside-m-edit-task-body .activity .result-with-image .res-col-2 table.properties {
        margin: 0; }
        .f-aside-m-edit-task-body .activity .result-with-image .res-col-2 table.properties > tbody > tr > td {
          text-align: left; }
          .f-aside-m-edit-task-body .activity .result-with-image .res-col-2 table.properties > tbody > tr > td span:first-child {
            font-size: 12px;
            font-weight: 500; }
          .f-aside-m-edit-task-body .activity .result-with-image .res-col-2 table.properties > tbody > tr > td span:last-child {
            font-size: 13px;
            font-weight: 700; }
  .f-aside-m-edit-task-body .activity hr {
    display: none; }
  .f-aside-m-edit-task-body .activity .title {
    background: #e2ebee;
    border-radius: 3px;
    margin-bottom: 6px; }
    .f-aside-m-edit-task-body .activity .title > tbody > tr > td {
      padding-left: 9px;
      text-align: left; }
      .f-aside-m-edit-task-body .activity .title > tbody > tr > td span {
        font-weight: 700; }
  .f-aside-m-edit-task-body .activity table.paproperties {
    width: 100%;
    margin: 0; }
    .f-aside-m-edit-task-body .activity table.paproperties > tbody > tr > td {
      text-align: left; }
      .f-aside-m-edit-task-body .activity table.paproperties > tbody > tr > td span:first-child {
        font-size: 12px;
        font-weight: 500; }
        .f-aside-m-edit-task-body .activity table.paproperties > tbody > tr > td span:first-child span {
          font-size: 12px;
          font-weight: 500; }
      .f-aside-m-edit-task-body .activity table.paproperties > tbody > tr > td span:last-child {
        font-size: 13px;
        font-weight: 700; }
  .f-aside-m-edit-task-body .activity .properties-note-activity {
    font-size: 12px;
    width: 100%; }
    .f-aside-m-edit-task-body .activity .properties-note-activity td {
      text-align: left; }
    .f-aside-m-edit-task-body .activity .properties-note-activity textarea {
      font-size: 14px;
      display: block;
      width: 100%;
      height: 4.5em;
      resize: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      margin-top: 5px; }
  .f-aside-m-edit-task-body .activity .no-results {
    background: white;
    border-radius: 3px;
    margin-bottom: 13px; }
  .f-aside-m-edit-task-body .activity .to-center {
    text-align: center;
    font-size: 12px; }
  .f-aside-m-edit-task-body .activity .wrap {
    position: relative;
    width: 100%;
    background: white;
    margin-bottom: 12px;
    padding: 10px 10px 0 10px;
    border-radius: 3px; }
  .f-aside-m-edit-task-body .activity .warning-on-save {
    display: inline-block;
    width: 100%;
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 14px;
    line-height: 18px;
    color: #8a6d3b;
    background: #fcf8e3;
    border: 1px solid #faebcc;
    border-radius: 3px; }
    .f-aside-m-edit-task-body .activity .warning-on-save:last-child {
      margin: 0 10px 10px 10px; }
  .f-aside-m-edit-task-body .activity .edit-link {
    position: relative;
    bottom: 18px;
    right: 10px;
    display: inline-block;
    font-size: 13px;
    float: right;
    color: #29af42;
    text-decoration: none; }
    .f-aside-m-edit-task-body .activity .edit-link:hover {
      color: #1d802f; }
  .f-aside-m-edit-task-body .activity .edit-mode {
    width: 300px;
    display: inline-block; }
  .f-aside-m-edit-task-body .activity-label {
    *zoom: 1;
    display: block;
    font-size: 12px;
    float: left;
    margin: 5px 0;
    width: 100%; }
    .f-aside-m-edit-task-body .activity-label:after {
      content: "";
      display: table;
      clear: both; }
  .f-aside-m-edit-task-body .activity-property {
    width: 100%;
    margin-bottom: 10px; }
    .f-aside-m-edit-task-body .activity-property div {
      width: 100%; }
    .f-aside-m-edit-task-body .activity-property button {
      position: relative;
      padding-right: 14px;
      min-width: 5.3571428571em;
      max-width: 11.5em;
      text-align: left; }
      .f-aside-m-edit-task-body .activity-property button > span {
        font-size: 12px;
        float: left;
        overflow: hidden;
        white-space: nowrap; }
        .f-aside-m-edit-task-body .activity-property button > span:first-child {
          width: 100%; }
        .f-aside-m-edit-task-body .activity-property button > span:last-child {
          position: absolute;
          top: 50%;
          right: 4px;
          margin-top: -2px; }
  .f-aside-m-edit-task-body .value {
    font-size: 13px;
    font-weight: 700; }
  .f-aside-m-edit-task-body .ui-slider, .f-aside-m-edit-task-body .ui-radiobox {
    width: 100%;
    line-height: 16px; }
    .f-aside-m-edit-task-body .ui-slider .labels, .f-aside-m-edit-task-body .ui-radiobox .labels {
      padding-bottom: 4px; }
      .f-aside-m-edit-task-body .ui-slider .labels > span, .f-aside-m-edit-task-body .ui-radiobox .labels > span {
        font-size: 13px;
        font-weight: 500; }
    .f-aside-m-edit-task-body .ui-slider .controls, .f-aside-m-edit-task-body .ui-radiobox .controls {
      padding-bottom: 4px; }
      .f-aside-m-edit-task-body .ui-slider .controls > div, .f-aside-m-edit-task-body .ui-radiobox .controls > div {
        margin-left: 5px; }
        .f-aside-m-edit-task-body .ui-slider .controls > div > span, .f-aside-m-edit-task-body .ui-radiobox .controls > div > span {
          cursor: pointer;
          font-size: 14px; }
        .f-aside-m-edit-task-body .ui-slider .controls > div > input[type="radio"], .f-aside-m-edit-task-body .ui-radiobox .controls > div > input[type="radio"] {
          cursor: pointer;
          width: 14px;
          height: 14px;
          margin: 0 2.5px 0 0; }
    .f-aside-m-edit-task-body .ui-slider .bubble-path, .f-aside-m-edit-task-body .ui-radiobox .bubble-path {
      position: relative;
      width: 100%;
      height: 4px;
      background: #d8d8d8;
      margin-bottom: 18px;
      margin-top: 9px; }
      .f-aside-m-edit-task-body .ui-slider .bubble-path .bubble, .f-aside-m-edit-task-body .ui-radiobox .bubble-path .bubble {
        cursor: pointer;
        position: absolute;
        height: 18px;
        width: 18px;
        top: 50%;
        left: 0;
        margin-top: -11px;
        background: #4cb141;
        border-radius: 50%;
        box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 0.9);
        transition: box-shadow .3s; }
  .f-aside-m-edit-task-body .drop-list {
    width: 150%;
    max-height: 300px;
    background: white;
    border: 2px solid #dcdedc;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(140, 140, 140, 0.5);
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 32px; }
    .f-aside-m-edit-task-body .drop-list > ul {
      overflow: hidden; }
      .f-aside-m-edit-task-body .drop-list > ul > li {
        cursor: pointer;
        position: relative;
        padding: 6px 16px;
        width: inherit;
        text-overflow: clip; }
        .f-aside-m-edit-task-body .drop-list > ul > li:hover {
          background: #edf5ec; }
  .f-aside-m-edit-task-body #ui-select {
    display: block;
    position: relative;
    background: none;
    border: none; }
  .f-aside-m-edit-task-body .edit-controls {
    width: 55%;
    margin-left: 45%;
    margin-top: 5px; }
    .f-aside-m-edit-task-body .edit-controls button {
      width: 50%;
      text-align: center;
      padding: 8px;
      font-size: 13px;
      float: left; }
      .f-aside-m-edit-task-body .edit-controls button:first-child {
        padding: 4px; }
      .f-aside-m-edit-task-body .edit-controls button:last-child {
        background: transparent;
        color: #4cb141;
        transition: color .3s; }
        .f-aside-m-edit-task-body .edit-controls button:last-child:hover {
          color: #1d802f; }
      .f-aside-m-edit-task-body .edit-controls button:after {
        display: block;
        content: '';
        clear: both; }
  .f-aside-m-edit-task-body .pa-disease-title {
    padding: 5px 5px 7px 5px;
    border-radius: 4px;
    background: #dff0d8; }
  .f-aside-m-edit-task-body .scout-movement {
    padding: 5px 5px 7px 5px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px; }
    .f-aside-m-edit-task-body .scout-movement input {
      margin-left: 5px;
      margin-right: 5px; }
  .f-aside-m-edit-task-body .middle-text {
    padding: 5px 5px 7px 5px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    background: #4CB141;
    color: white; }

.disease-scale {
  border-radius: 6px;
  background: lightgrey;
  opacity: 0.95;
  display: inline-block;
  min-width: 106px;
  position: absolute;
  left: 460px;
  bottom: 10px;
  min-height: 28px; }
  .disease-scale .item {
    font-size: 12px;
    padding: 5px;
    display: flex; }
    .disease-scale .item .col {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 1px;
      border: 1px solid grey; }
    .disease-scale .item .name {
      position: relative;
      left: 16px;
      max-width: 80px; }

.warning-container {
  position: absolute;
  width: 400px;
  left: 470px;
  top: 20px;
  z-index: -1; }
  .warning-container .warning-item {
    background: rgba(0, 0, 0, 0.5);
    background-image: url("/img/warning-white.22122017.png");
    background-repeat: no-repeat;
    padding: 10px 10px 10px 60px;
    background-position: 18px center;
    color: white;
    font-size: 15px;
    border-radius: 6px;
    margin-bottom: 10px; }
    .warning-container .warning-item .edit-threshold {
      color: #83f475; }

div.pa-table-container {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ccc; }

.insect-list {
  padding: 5px 5px 7px 5px;
  background: #fff;
  border-radius: 4px;
  margin-top: 4px;
  cursor: pointer; }
  .insect-list.selected {
    background: #97ECFF; }
  .insect-list .insect-note-switch {
    background: url(/img/photo-note-icon-2.27112017.png);
    background-size: cover;
    height: 20px;
    width: 21px;
    float: right; }
    .insect-list .insect-note-switch.selected {
      background: url("/img/photo-note-icon-1.27112017.png");
      background-size: cover;
      height: 20px;
      width: 21px; }
  .insect-list .note-insect {
    background: url("/img/photo-note-icon-1.27112017.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position-y: 2px;
    padding-left: 24px; }

table.pa-table {
  margin-bottom: 0; }
  table.pa-table thead tr {
    background: #E2EBEE; }
    table.pa-table thead tr th {
      padding: 3px;
      color: #29545E;
      font-size: 12px;
      font-weight: 600; }
  table.pa-table tbody {
    background: white;
    font-size: 14px; }
    table.pa-table tbody select {
      width: 100%;
      border: 1px solid lightgray; }
    table.pa-table tbody .insect-stage {
      font-size: 12px;
      letter-spacing: -1px; }
    table.pa-table tbody input.plant-num {
      width: 36px;
      text-align: right;
      border: 1px solid lightgray;
      height: 21px; }
    table.pa-table tbody td {
      padding: 3px;
      vertical-align: top; }
    table.pa-table tbody td.col1 {
      min-width: 250px;
      text-align: left; }
      table.pa-table tbody td.col1 label {
        margin-right: 10px; }
    table.pa-table tbody td.col2 {
      min-width: 75px; }
    table.pa-table tbody td.col3 {
      min-width: 249px; }
    table.pa-table tbody i.remove {
      color: lightgrey;
      cursor: pointer; }
      table.pa-table tbody i.remove:hover {
        color: #f56423; }
    table.pa-table tbody td.add {
      text-align: right;
      padding: 7px;
      border-top: 1px solid #eee; }
      table.pa-table tbody td.add span {
        cursor: pointer;
        color: #4DB141; }

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.f-aside-spray {
  z-index: 2;
  position: relative;
  width: 450px;
  height: 100%;
  background: #f0f0f0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  text-align: left;
  /**
   * FadeIn/FadeOut Animation
   */ }
  .f-aside-spray.ng-enter, .f-aside-spray.ng-leave {
    animation-duration: .3s;
    animation-fill-mode: both; }
  .f-aside-spray.ng-enter, .f-aside-spray.ng-leave.ng-leave-active {
    animation-name: fadeOutLeft; }
  .f-aside-spray.ng-leave, .f-aside-spray.ng-enter.ng-enter-active {
    animation-name: fadeInLeft; }
  .f-aside-spray .grid-tab > ul > li > a {
    padding: 4px;
    font-size: 12px; }
  .f-aside-spray .grid-tab .tab-content {
    padding: 4px;
    margin-bottom: 10px; }
  .f-aside-spray--zoom-controls {
    position: fixed;
    right: 40px;
    top: 140px; }
    .f-aside-spray--zoom-controls input[type="button"] {
      display: block;
      font-size: 11px;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      background: white;
      color: #979797;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      cursor: pointer; }

.f-aside-spray-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-bottom: 1px solid #d5d5d3;
  background: #f9fdfe;
  font-weight: 700; }
  .f-aside-spray-header > span {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: #073743;
    /*firefox font bold fix*/
    font-weight: 200 !important; }
  .f-aside-spray-header [uib-dropdown] {
    position: absolute;
    top: 50%;
    right: 48px;
    width: 32px;
    height: 32px;
    margin-top: -16px; }
  .f-aside-spray-header .f-aside-w-info-header--close {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 28px;
    height: 28px;
    margin-top: -14px;
    background: url("/img/close_btn.22042016.png"); }

.f-aside-spray-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 45px);
  padding: 10px 30px;
  background: #f3f3f0; }
  .f-aside-spray-body .steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700; }
    .f-aside-spray-body .steps--final {
      text-transform: none;
      display: flex; }
      .f-aside-spray-body .steps--final .edit {
        margin-left: 5px;
        font-weight: 700;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        display: inline-block;
        padding-left: 20px;
        line-height: normal;
        color: #669a1f !important;
        font-size: 12px; }
        .f-aside-spray-body .steps--final .edit:before {
          content: '';
          position: absolute;
          top: -3px;
          left: 0;
          display: block;
          width: 18px;
          height: 19px;
          background: url(/img/edit_icon.22042016.png); }
    .f-aside-spray-body .steps svg {
      width: 24px;
      height: 24px; }
      .f-aside-spray-body .steps svg path {
        fill: #ccc; }
    .f-aside-spray-body .steps > div {
      color: #ccc; }
      .f-aside-spray-body .steps > div.active {
        color: #25606f; }
        .f-aside-spray-body .steps > div.active > span {
          font-size: 90%; }
  .f-aside-spray-body .pa-disease-title {
    padding: 5px 5px 7px 5px;
    border-radius: 4px;
    background: #dff0d8; }
  .f-aside-spray-body .pa-disease-element {
    padding: 5px 5px 7px 5px;
    background: #fff;
    border-radius: 4px;
    margin-top: 4px;
    cursor: pointer; }
    .f-aside-spray-body .pa-disease-element.selected {
      background: #97ECFF; }
  .f-aside-spray-body hr {
    border-bottom: 2px solid #e1e1e0; }
  .f-aside-spray-body table {
    width: 100%; }
    .f-aside-spray-body table > tbody > tr > td {
      vertical-align: middle;
      padding: 4px; }
      .f-aside-spray-body table > tbody > tr > td:first-child {
        text-align: right;
        color: #466872;
        white-space: nowrap; }
        .f-aside-spray-body table > tbody > tr > td:first-child > label > span {
          font: 700 12px "Roboto-Light";
          /* For all Mozilla Firefox browsers */ }

@-moz-document url-prefix() {
  .f-aside-spray-body table > tbody > tr > td:first-child > label > span {
    font-size: 11px; } }
      .f-aside-spray-body table > tbody > tr > td:last-child {
        font-size: 13px; }
  .f-aside-spray-body .checkbox label {
    font: 700 13px "Roboto-Light";
    color: #466872; }
    .f-aside-spray-body .checkbox label:before {
      outline: 0 !important; }
    .f-aside-spray-body .checkbox label:after {
      padding: 0;
      margin: 0 0 0 -15px;
      font-size: 14px;
      color: #979797; }
  .f-aside-spray-body .controls {
    display: flex; }
    .f-aside-spray-body .controls .btn {
      width: 50%;
      outline: 0; }
      .f-aside-spray-body .controls .btn[disabled], .f-aside-spray-body .controls .btn[disabled]:hover, .f-aside-spray-body .controls .btn[disabled]:focus {
        background: #dcdcdc;
        border-color: #dcdcdc; }
    .f-aside-spray-body .controls .btn-link {
      color: #3bb453;
      font-weight: 700;
      text-decoration: none; }
      .f-aside-spray-body .controls .btn-link:hover {
        color: #287a38; }
  .f-aside-spray-body .input-group .form-control {
    border-radius: 4px; }
  .f-aside-spray-body .input-group .input-group-addon {
    border: none;
    background: none; }
    .f-aside-spray-body .input-group .input-group-addon svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      cursor: pointer; }
      .f-aside-spray-body .input-group .input-group-addon svg path {
        fill: #4db141; }
  .f-aside-spray-body .help-block {
    white-space: normal; }
  .f-aside-spray-body .step1 .insects h2 {
    padding: 0 5px;
    font-weight: 700;
    font-size: 90%;
    color: #395e68; }
  .f-aside-spray-body .step1 .insects p {
    font-size: 90%; }
  .f-aside-spray-body .step1 .input-group input {
    font-size: 11px;
    padding: 5px 0 5px 5px; }
  .f-aside-spray-body .step1 .input-group-addon {
    font-size: 11px;
    padding: 5px 0px 5px 2px;
    /* For all Mozilla Firefox browsers */ }

@-moz-document url-prefix() {
  .f-aside-spray-body .step1 .input-group-addon {
    font-size: 10px; } }
  .f-aside-spray-body .step2 p {
    margin-bottom: 15px;
    font-size: 15px;
    color: #666; }
  .f-aside-spray-body .step2 .btn-block.btn-link {
    color: #4cb141;
    text-align: left;
    font-weight: 700; }
  .f-aside-spray-body .step3 table tr td:first-child {
    text-align: left; }
  .f-aside-spray-body .step3 .dropdown > .form-control {
    text-align: left;
    font-weight: 300;
    cursor: default; }
    .f-aside-spray-body .step3 .dropdown > .form-control .caret {
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -2px; }
  .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div {
      padding: 6px; }
      .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(1) {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font: 700 13px "Roboto-Light";
        color: #466872; }
      .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) {
        flex: 0 1 0; }
        .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) .input-group-addon {
          padding: 6px; }
        .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) input[type=number] {
          -moz-appearance: textfield;
          /* Re-apply the controls on :hover and :focus */
          min-width: 50px;
          text-align: right; }
          .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) input[type=number]::-webkit-inner-spin-button, .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0; }
          .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) input[type=number]:hover, .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div:nth-child(2) input[type=number]:focus {
            -moz-appearance: number-input; }
      .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div button {
        color: #ccc; }
        .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div button:hover, .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div button:focus {
          color: #a6a6a6; }
        .f-aside-spray-body .step3 .custom-mix .custom-mix-container > div > div button[disabled] {
          cursor: not-allowed;
          color: rgba(0, 0, 0, 0.3); }

.dropdown-menu.mixes,
.dropdown-menu.guides,
.dropdown-menu.products {
  padding: 0;
  margin: 0;
  border: 1px solid #dfdfde;
  max-width: 250px; }
  .dropdown-menu.mixes.products ul li,
  .dropdown-menu.guides.products ul li,
  .dropdown-menu.products.products ul li {
    padding: 20px 4px; }
  .dropdown-menu.mixes ul,
  .dropdown-menu.guides ul,
  .dropdown-menu.products ul {
    *zoom: 1; }
    .dropdown-menu.mixes ul:after,
    .dropdown-menu.guides ul:after,
    .dropdown-menu.products ul:after {
      content: "";
      display: table;
      clear: both; }
    .dropdown-menu.mixes ul li,
    .dropdown-menu.guides ul li,
    .dropdown-menu.products ul li {
      padding: 3px 4px; }
    .dropdown-menu.mixes ul:last-child,
    .dropdown-menu.guides ul:last-child,
    .dropdown-menu.products ul:last-child {
      max-height: 250px;
      overflow-x: hidden;
      border-bottom: 2px solid #dfdfde;
      background: #f8fbf4; }
      .dropdown-menu.mixes ul:last-child li,
      .dropdown-menu.guides ul:last-child li,
      .dropdown-menu.products ul:last-child li {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        line-height: 18px;
        padding: 6px;
        margin: 1.5px 0; }
        .dropdown-menu.mixes ul:last-child li .item-icon,
        .dropdown-menu.guides ul:last-child li .item-icon,
        .dropdown-menu.products ul:last-child li .item-icon {
          min-width: 32px; }
        .dropdown-menu.mixes ul:last-child li .item-data,
        .dropdown-menu.guides ul:last-child li .item-data,
        .dropdown-menu.products ul:last-child li .item-data {
          max-width: 100%;
          white-space: normal;
          padding-left: 5px; }
        .dropdown-menu.mixes ul:last-child li:hover,
        .dropdown-menu.guides ul:last-child li:hover,
        .dropdown-menu.products ul:last-child li:hover {
          background: #ecf4e1; }
        .dropdown-menu.mixes ul:last-child li div,
        .dropdown-menu.guides ul:last-child li div,
        .dropdown-menu.products ul:last-child li div {
          margin-left: 10px; }
    .dropdown-menu.mixes ul:first-child li,
    .dropdown-menu.guides ul:first-child li,
    .dropdown-menu.products ul:first-child li {
      padding: 6px; }
      .dropdown-menu.mixes ul:first-child li .form-control,
      .dropdown-menu.guides ul:first-child li .form-control,
      .dropdown-menu.products ul:first-child li .form-control {
        font-weight: 300; }
      .dropdown-menu.mixes ul:first-child li .form-control-feedback,
      .dropdown-menu.guides ul:first-child li .form-control-feedback,
      .dropdown-menu.products ul:first-child li .form-control-feedback {
        top: 0;
        pointer-events: none;
        color: #d8d8d8; }

.dropdown-menu.users {
  padding: 0;
  margin: 0;
  border: 1px solid #dfdfde; }
  .dropdown-menu.users ul {
    max-height: 250px;
    overflow: auto;
    border-bottom: 2px solid #dfdfde; }
    .dropdown-menu.users ul li {
      display: flex;
      align-items: center;
      padding: 20px 4px;
      height: 32px;
      cursor: pointer;
      background: #f9fdfe; }
      .dropdown-menu.users ul li:hover {
        background: #ddf0f5; }
      .dropdown-menu.users ul li div {
        margin-left: 10px; }

c-spray-graph .spray-graph {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1; }

c-calendar {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 54px;
  background: #293028;
  color: white;
  font-weight: lighter;
  z-index: 1000; }
  c-calendar .c-calendar-granularities {
    z-index: 5;
    position: absolute;
    bottom: 57px;
    left: 0;
    width: 67px;
    height: 114px;
    padding: 4px;
    border-radius: 0 5px 5px 0;
    background: #023a48;
    text-align: center; }
    c-calendar .c-calendar-granularities .c-calendar-granularities-reset {
      width: 58px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
      background: #f56423;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px; }
      c-calendar .c-calendar-granularities .c-calendar-granularities-reset:hover {
        background: #f3530b; }
    c-calendar .c-calendar-granularities .c-calendar-granularities-controller {
      width: 58px;
      height: 70px;
      padding: 3px;
      margin-top: 5px;
      border-radius: 3px;
      background: #a2dd3b; }
      c-calendar .c-calendar-granularities .c-calendar-granularities-controller button {
        display: block;
        width: 52px;
        height: 22px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.15); }
        c-calendar .c-calendar-granularities .c-calendar-granularities-controller button svg {
          fill: #4a4a4a; }
        c-calendar .c-calendar-granularities .c-calendar-granularities-controller button[disabled] {
          background: none; }
          c-calendar .c-calendar-granularities .c-calendar-granularities-controller button[disabled] svg {
            fill: #9cba70; }
      c-calendar .c-calendar-granularities .c-calendar-granularities-controller .c-calendar-granularitis-controller-current {
        width: 52px;
        height: 21px;
        line-height: 21px;
        color: #242424;
        font-family: "Roboto-Medium";
        font-size: 14px; }
  c-calendar .c-calendar--prev, c-calendar .c-calendar--next, c-calendar .c-calendar .c-calendar--prev, .c-calendar c-calendar .c-calendar--prev, c-calendar .c-calendar .c-calendar--next, .c-calendar c-calendar .c-calendar--next {
    display: block;
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 46px; }
  c-calendar .c-calendar--prev {
    width: 49px;
    left: 0; }
    c-calendar .c-calendar--prev > img {
      margin: 0 0 1px 14px;
      display: block; }
  c-calendar .c-calendar--next {
    width: 47px;
    right: 0; }
    c-calendar .c-calendar--next > img {
      margin: 0 0 1px 16px;
      display: block; }
  c-calendar .c-calendar-items {
    height: 50%;
    text-align: center; }
    c-calendar .c-calendar-items.is-h, c-calendar .c-calendar-items.is-w {
      text-align: left; }
    c-calendar .c-calendar-items.is-d .c-calendar-items-item span span {
      text-transform: capitalize;
      font-family: "Roboto-Light"; }
    c-calendar .c-calendar-items .c-grid--row {
      height: 100%; }
    c-calendar .c-calendar-items .c-calendar-items-item {
      height: 100%;
      margin-top: 3px;
      padding-left: 5px;
      position: relative; }
      c-calendar .c-calendar-items .c-calendar-items-item .photo-note-in-calendar {
        position: absolute;
        left: 5px;
        top: -6px; }
      c-calendar .c-calendar-items .c-calendar-items-item .c-calendar-items-item--label {
        display: block;
        font-family: "Roboto-Medium";
        border-bottom: 4px solid #7c9e4a;
        height: 100%;
        margin-top: -5px;
        line-height: 30px;
        font-size: 13px;
        text-transform: uppercase;
        color: #fff; }
      c-calendar .c-calendar-items .c-calendar-items-item .c-calendar-items-item--label-active {
        color: #FFFF00; }

c-calendar-today {
  display: block;
  position: absolute;
  z-index: 3;
  top: 30%;
  background: #f56423;
  color: white;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  margin-top: -24px;
  margin-left: -27.5px;
  text-align: center;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4); }
  c-calendar-today .c-calendar-today--main {
    font-family: "Roboto-Medium";
    font-size: 14px;
    color: #fff;
    display: block;
    margin-top: 3px; }
  c-calendar-today .c-calendar-today--sub {
    text-transform: uppercase;
    font-family: "Roboto-Light";
    font-size: 10px;
    color: #fff;
    line-height: 12px;
    display: block;
    padding-top: 2px; }

.c-calendar-date-hint div {
  z-index: 2;
  position: absolute;
  bottom: 0;
  display: flex;
  height: 100%;
  width: 0;
  padding-left: 2px;
  padding-bottom: 6px;
  white-space: nowrap;
  justify-content: center;
  align-items: center; }
  .c-calendar-date-hint div span {
    align-self: flex-end;
    font-family: "Roboto-Light";
    font-size: 80%;
    text-transform: uppercase;
    letter-spacing: 1px; }

.c-calendar {
  position: relative;
  display: block;
  height: 54px;
  background: #293028;
  color: white;
  font-weight: lighter;
  z-index: 1000; }
  .c-calendar .c-calendar--prev {
    width: 49px;
    left: 0; }
    .c-calendar .c-calendar--prev > img {
      margin: 0 0 1px 14px;
      display: block; }
  .c-calendar .c-calendar--next {
    width: 49px;
    right: 0; }
    .c-calendar .c-calendar--next > img {
      margin: 0 0 1px 16px;
      display: block; }
  .c-calendar .c-calendar-ri,
  .c-calendar .c-calendar-le {
    position: absolute;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); }
  .c-calendar .c-calendar-items {
    height: 100%; }
    .c-calendar .c-calendar-items ul {
      height: inherit; }
      .c-calendar .c-calendar-items ul li {
        height: inherit; }
        .c-calendar .c-calendar-items ul li span {
          padding-top: 3px;
          height: 50%;
          font-size: 13px;
          color: #9ba1a3; }
          .c-calendar .c-calendar-items ul li span:first-child {
            display: block;
            width: 100%;
            padding-top: 7px;
            padding-bottom: 3px;
            border-bottom: 3px solid #6b726d; }
          .c-calendar .c-calendar-items ul li span span {
            display: inline !important;
            border-bottom: none !important; }
  .c-calendar .c-calendar-items-item--label-months {
    display: block;
    text-align: left; }

.f-panel {
  overflow: visible;
  position: relative;
  border-top: #9ba79f; }
  .f-panel.is-settings-open {
    z-index: auto; }
  .f-panel .f-w--spinner {
    z-index: 1000; }

.f-panel-fields {
  height: 120px;
  overflow: auto;
  overflow-x: hidden;
  background: linear-gradient(to bottom, #63adc1 0%, #5ea7ba 100%); }
  .f-panel-fields-field {
    position: relative;
    height: 40px;
    border-top: 1px solid #76b3c4;
    border-bottom: 1px solid #76b3c4;
    background: linear-gradient(to bottom, #63adc1 0%, #5ea7ba 100%); }
    + .f-panel-fields-field-field {
      border-top: 0; }
    .f-panel-fields-field--selected {
      background: linear-gradient(to bottom, #2a6b7d 0%, #0f4958 100%); }
      .f-panel-fields-field--selected .f-panel-fields-field-label {
        background: #023a48; }
    .f-panel-fields-field-label {
      cursor: pointer;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 50px;
      display: block;
      width: 110px;
      height: 30px;
      padding-left: 30px;
      padding-right: 12px;
      line-height: 30px;
      border-bottom-right-radius: 4px;
      background: #33545c;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "Roboto-Light";
      font-size: 12px;
      color: #fff;
      font-family: "Roboto-Medium"; }
    .f-panel-fields-field .f-panel-crops {
      position: relative; }
      .f-panel-fields-field .f-panel-crops-crop {
        position: relative; }
        .f-panel-fields-field .f-panel-crops-crop.active {
          z-index: 6; }
        .f-panel-fields-field .f-panel-crops-crop-label {
          z-index: 1;
          overflow: hidden;
          position: absolute;
          height: 30px;
          border-radius: 0 0 4px 4px;
          box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4);
          white-space: nowrap;
          text-overflow: ellipsis; }
          .f-panel-fields-field .f-panel-crops-crop-label img {
            position: absolute;
            left: 0;
            max-height: 100%; }
          .f-panel-fields-field .f-panel-crops-crop-label span {
            padding-left: 40px;
            font-size: 12px;
            line-height: 30px; }
          .f-panel-fields-field .f-panel-crops-crop-label:hover, .f-panel-fields-field .f-panel-crops-crop-label:active, .f-panel-fields-field .f-panel-crops-crop-label.active {
            cursor: pointer; }
            .f-panel-fields-field .f-panel-crops-crop-label:hover .f-panel-crops-crop-label-settings, .f-panel-fields-field .f-panel-crops-crop-label:active .f-panel-crops-crop-label-settings, .f-panel-fields-field .f-panel-crops-crop-label.active .f-panel-crops-crop-label-settings {
              opacity: 1; }
          .f-panel-fields-field .f-panel-crops-crop-label-settings {
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            margin: 3px 3px 3px 0;
            padding: 4px;
            opacity: .2;
            border-radius: 5px;
            transition: opacity .2s ease, background .2s ease; }
            .f-panel-fields-field .f-panel-crops-crop-label-settings svg {
              width: 16px;
              height: 16px; }
          .f-panel-fields-field .f-panel-crops-crop-label-delete {
            position: absolute;
            top: 0;
            right: 24px;
            width: 24px;
            height: 24px;
            margin: 3px 3px 3px 0;
            padding: 4px;
            opacity: .2;
            border-radius: 5px;
            transition: opacity .2s ease, background .2s ease; }
            .f-panel-fields-field .f-panel-crops-crop-label-delete svg {
              width: 16px;
              height: 16px; }
            .f-panel-fields-field .f-panel-crops-crop-label-delete:hover {
              opacity: 1; }
        .f-panel-fields-field .f-panel-crops-crop-stagesbar {
          top: 77px;
          position: absolute;
          width: 100%;
          z-index: 5;
          cursor: pointer; }
          .f-panel-fields-field .f-panel-crops-crop-stagesbar-label {
            display: inline-block;
            padding-left: 5px; }
            .f-panel-fields-field .f-panel-crops-crop-stagesbar-label-text {
              display: inline-block;
              width: 100%;
              text-align: center;
              font-size: 12px;
              border-bottom: 3px solid #7c9e4a;
              line-height: 27px; }
      .f-panel-fields-field .f-panel-crops-crop-label {
        top: 4px;
        height: 30px;
        border-radius: 4px; }
      .f-panel-fields-field .f-panel-crops .f-panel-tasks {
        display: none; }

.disease-graph {
  position: absolute;
  display: none;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  padding: 150px 550px 330px 0;
  left: 500px; }
  .disease-graph .disease-graph-inner {
    min-height: 400px;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    border-radius: 10px; }
    .disease-graph .disease-graph-inner .header {
      height: 50px;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .disease-graph .disease-graph-inner .header .exclamation-circle {
        display: inline-block;
        margin-top: 11px;
        margin-left: 14px;
        margin-right: 7px;
        height: 27px;
        width: 27px;
        background-image: url(/img/exMark.26082017.png); }
      .disease-graph .disease-graph-inner .header .disease-name {
        color: white;
        font-size: 20px;
        top: -7px;
        position: relative;
        display: inline-block; }
      .disease-graph .disease-graph-inner .header .close-me {
        background-image: url(/img/close_1.26082017.png);
        float: right;
        width: 20px;
        height: 20px;
        margin-right: 13px;
        margin-top: 14px;
        cursor: pointer; }
    .disease-graph .disease-graph-inner .disease-graph-body {
      width: 100%;
      height: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-bottom: 50px; }

.f-panel-tasks-overlap-icon-container {
  position: relative;
  z-index: 200; }
  .f-panel-tasks-overlap-icon-container .f-panel-tasks-overlap-icon {
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    top: 5px;
    width: 27px !important;
    height: 27px !important;
    background-image: url(/img/exMark.26082017.png); }

.f-panel-tasks-overlap-line {
  position: relative; }
  .f-panel-tasks-overlap-line .f-panel-tasks-overlap {
    overflow: hidden;
    height: 30px;
    background: #F56423;
    border: 2px solid white;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    z-index: 1; }
    .f-panel-tasks-overlap-line .f-panel-tasks-overlap i {
      color: #FFC4A9;
      top: 2px;
      position: relative;
      font-size: 23px;
      left: 4px; }
    .f-panel-tasks-overlap-line .f-panel-tasks-overlap .disease-name {
      color: white;
      font-size: 11px;
      position: absolute;
      left: 0;
      padding-left: 26px;
      padding-top: 2px;
      width: 100%;
      height: 25px;
      overflow: hidden; }

.f-panel-single-field {
  height: 120px;
  overflow-x: visible;
  position: relative;
  border-top: 1px solid #799a9c;
  border-bottom: 1px solid #4c514a;
  background: linear-gradient(to bottom, #2a6b7c 0%, #043947 100%); }
  .f-panel-single-field .f-panel-crops {
    position: relative; }
    .f-panel-single-field .f-panel-crops-crop {
      position: relative; }
      .f-panel-single-field .f-panel-crops-crop.active {
        z-index: 6; }
      .f-panel-single-field .f-panel-crops-crop-label {
        z-index: 1;
        overflow: hidden;
        position: absolute;
        height: 30px;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4);
        white-space: nowrap;
        text-overflow: ellipsis; }
        .f-panel-single-field .f-panel-crops-crop-label img {
          position: absolute;
          left: 0;
          max-height: 100%; }
        .f-panel-single-field .f-panel-crops-crop-label span {
          padding-left: 40px;
          font-size: 12px;
          line-height: 30px; }
        .f-panel-single-field .f-panel-crops-crop-label:hover, .f-panel-single-field .f-panel-crops-crop-label:active, .f-panel-single-field .f-panel-crops-crop-label.active {
          cursor: pointer; }
          .f-panel-single-field .f-panel-crops-crop-label:hover .f-panel-crops-crop-label-settings, .f-panel-single-field .f-panel-crops-crop-label:active .f-panel-crops-crop-label-settings, .f-panel-single-field .f-panel-crops-crop-label.active .f-panel-crops-crop-label-settings {
            opacity: 1; }
        .f-panel-single-field .f-panel-crops-crop-label-settings {
          position: absolute;
          top: 0;
          right: 0;
          width: 24px;
          height: 24px;
          margin: 3px 3px 3px 0;
          padding: 4px;
          opacity: .2;
          border-radius: 5px;
          transition: opacity .2s ease, background .2s ease; }
          .f-panel-single-field .f-panel-crops-crop-label-settings svg {
            width: 16px;
            height: 16px; }
        .f-panel-single-field .f-panel-crops-crop-label-delete {
          position: absolute;
          top: 0;
          right: 24px;
          width: 24px;
          height: 24px;
          margin: 3px 3px 3px 0;
          padding: 4px;
          opacity: .2;
          border-radius: 5px;
          transition: opacity .2s ease, background .2s ease; }
          .f-panel-single-field .f-panel-crops-crop-label-delete svg {
            width: 16px;
            height: 16px; }
          .f-panel-single-field .f-panel-crops-crop-label-delete:hover {
            opacity: 1; }
      .f-panel-single-field .f-panel-crops-crop-stagesbar {
        top: 77px;
        position: absolute;
        width: 100%;
        z-index: 5;
        cursor: pointer; }
        .f-panel-single-field .f-panel-crops-crop-stagesbar-label {
          display: inline-block;
          padding-left: 5px; }
          .f-panel-single-field .f-panel-crops-crop-stagesbar-label-text {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            border-bottom: 3px solid #7c9e4a;
            line-height: 27px; }
    .f-panel-single-field .f-panel-crops-crop-label {
      top: 77px; }
    .f-panel-single-field .f-panel-crops .f-panel-tasks-background {
      position: absolute;
      top: 10px;
      height: 67px;
      background: #103c49;
      border-radius: 4px 4px 0 0; }
    .f-panel-single-field .f-panel-crops .f-panel-tasks-task {
      position: absolute;
      overflow: hidden;
      height: 67px;
      top: 10px; }
      .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini {
        cursor: pointer;
        width: 3px !important;
        z-index: 6; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini.is-not-active {
          opacity: .6;
          transition: opacity .5s ease-in-out; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini.is-spray {
          background: #646034; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini.is-cropmonitor {
          background: #3b643e; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini.is-active {
          z-index: 6;
          opacity: 1;
          transition: opacity .5s ease-in-out; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-mini.is-active:hover {
            z-index: 6; }
      .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full {
        height: 60px;
        margin-top: 3px;
        border-radius: 4px;
        box-shadow: 0 0 8px -2px black;
        cursor: pointer;
        z-index: 5; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full.is-not-active {
          opacity: .6;
          transition: opacity .5s ease-in-out; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full.is-spray {
          background: #e89c20; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full.is-cropmonitor {
          background: #b3d82b; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full.is-active {
          z-index: 1000;
          opacity: 1;
          transition: opacity .5s ease-in-out; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full.is-active:hover {
            z-index: 1000; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full:hover {
          z-index: 1000;
          transition: z-index 0s ease-in-out; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-heading {
          height: 10px; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-heading span {
            width: 8px;
            height: 1px;
            display: block;
            background: #fff;
            position: absolute;
            left: 50%;
            top: 4px;
            margin-left: -4px; }
            .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-heading span:nth-child(2) {
              top: 6px; }
        .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body {
          height: 50px;
          background: #fff; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body-type {
            padding: 4px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body-avatar {
            height: 22px;
            width: 22px;
            margin: 0 auto;
            background: no-repeat center center;
            border-radius: 50%; }
          .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body-status {
            display: none;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 15px;
            color: #fff;
            text-align: center;
            font-size: 13px;
            line-height: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body-status.is-DONE {
              display: block;
              background: #50a5bc; }
            .f-panel-single-field .f-panel-crops .f-panel-tasks-task-full-body-status.is-ABANDONED {
              display: block;
              background: #bc0002; }

.popover.f-panel-tasks-task-full {
  pointer-events: none;
  height: 60px;
  min-width: 60px;
  padding: 0;
  margin-top: 0px !important;
  border: none;
  border-radius: 4px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35); }
  .popover.f-panel-tasks-task-full .popover-content {
    padding: 0; }
  .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini {
    cursor: pointer;
    width: 3px !important;
    z-index: 6; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini.is-not-active {
      opacity: .6;
      transition: opacity .5s ease-in-out; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini.is-spray {
      background: #646034; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini.is-cropmonitor {
      background: #3b643e; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini.is-active {
      z-index: 6;
      opacity: 1;
      transition: opacity .5s ease-in-out; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-mini.is-active:hover {
        z-index: 6; }
  .popover.f-panel-tasks-task-full .f-panel-tasks-task-full {
    height: 60px;
    margin-top: 3px;
    border-radius: 4px;
    box-shadow: 0 0 8px -2px black;
    cursor: pointer;
    z-index: 5; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full.is-not-active {
      opacity: .6;
      transition: opacity .5s ease-in-out; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full.is-spray {
      background: #e89c20; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full.is-cropmonitor {
      background: #b3d82b; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full.is-active {
      z-index: 1000;
      opacity: 1;
      transition: opacity .5s ease-in-out; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full.is-active:hover {
        z-index: 1000; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full:hover {
      z-index: 1000;
      transition: z-index 0s ease-in-out; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-heading {
      height: 10px; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-heading span {
        width: 8px;
        height: 1px;
        display: block;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 4px;
        margin-left: -4px; }
        .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-heading span:nth-child(2) {
          top: 6px; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body {
      height: 50px;
      background: #fff; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-type {
        padding: 4px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-avatar {
        height: 22px;
        width: 22px;
        margin: 0 auto;
        background: no-repeat center center;
        border-radius: 50%; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-status {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 15px;
        color: #fff;
        text-align: center;
        font-size: 13px;
        line-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-status.is-DONE {
          display: block;
          background: #50a5bc; }
        .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-status.is-ABANDONED {
          display: block;
          background: #bc0002; }
  .popover.f-panel-tasks-task-full .f-panel-tasks-task-full {
    margin-top: 0; }
    .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body {
      padding: 0 10px;
      height: 48px; }
      .popover.f-panel-tasks-task-full .f-panel-tasks-task-full-body-status {
        display: none !important; }

.popover.popup-crop-addTask {
  padding: 0;
  border: none;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
  z-index: 1000; }
  .popover.popup-crop-addTask .header {
    width: 100%;
    height: 16px;
    border-radius: 3px 3px 0 0;
    background: #b3d82b;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .popover.popup-crop-addTask .header span {
      display: block;
      width: 8px;
      height: 1px;
      margin: 1px;
      background: #fff; }
  .popover.popup-crop-addTask .popover-content {
    padding: 0; }
    .popover.popup-crop-addTask .popover-content .content {
      padding: 20px 15px;
      color: #4a4a4a;
      font-family: "Roboto-Light";
      font-weight: 700;
      font-size: 13px; }
      .popover.popup-crop-addTask .popover-content .content .control-label {
        margin-bottom: 10px; }
      .popover.popup-crop-addTask .popover-content .content .form-group {
        margin-bottom: 20px; }
      .popover.popup-crop-addTask .popover-content .content .user-list {
        position: relative;
        margin-bottom: 20px; }
        .popover.popup-crop-addTask .popover-content .content .user-list > span {
          display: block;
          margin-bottom: 5px; }
        .popover.popup-crop-addTask .popover-content .content .user-list ul {
          display: flex; }
          .popover.popup-crop-addTask .popover-content .content .user-list ul li {
            display: flex;
            align-items: center; }
            .popover.popup-crop-addTask .popover-content .content .user-list ul li:hover {
              cursor: pointer;
              background: #c3dee5;
              border-radius: 5px; }
            .popover.popup-crop-addTask .popover-content .content .user-list ul li .avatar {
              display: block;
              width: 45px;
              height: 45px;
              background-position: center; }
            .popover.popup-crop-addTask .popover-content .content .user-list ul li .name {
              display: none; }
        .popover.popup-crop-addTask .popover-content .content .user-list .dropdown {
          position: static; }
          .popover.popup-crop-addTask .popover-content .content .user-list .dropdown .dropdown-menu {
            top: 11px;
            width: 100%;
            max-height: 135px;
            overflow: auto; }
            .popover.popup-crop-addTask .popover-content .content .user-list .dropdown .dropdown-menu ul {
              flex-direction: column; }
              .popover.popup-crop-addTask .popover-content .content .user-list .dropdown .dropdown-menu ul li {
                align-items: center;
                border-radius: 0; }
                .popover.popup-crop-addTask .popover-content .content .user-list .dropdown .dropdown-menu ul li .name {
                  flex: 1;
                  display: block;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden; }
        .popover.popup-crop-addTask .popover-content .content .user-list .btn-show-more {
          margin-top: 5px;
          height: 26px;
          width: 72px;
          line-height: 26px;
          padding: 0;
          outline: none;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          font-size: 11px;
          background: #eef8f9;
          color: #8D9FA5; }
          .popover.popup-crop-addTask .popover-content .content .user-list .btn-show-more:focus, .popover.popup-crop-addTask .popover-content .content .user-list .btn-show-more:hover {
            background: #dbf0f2;
            border-color: #d7d7d7; }
      .popover.popup-crop-addTask .popover-content .content .controls {
        display: flex; }
        .popover.popup-crop-addTask .popover-content .content .controls .btn {
          height: 34px;
          line-height: 34px;
          padding-top: 0;
          padding-bottom: 0;
          outline: none;
          border: none;
          font-size: 15px; }
        .popover.popup-crop-addTask .popover-content .content .controls .btn-success {
          flex: 1;
          color: #fff;
          background: linear-gradient(to bottom, #9fe12a 0%, #689f38 100%); }
          .popover.popup-crop-addTask .popover-content .content .controls .btn-success:focus, .popover.popup-crop-addTask .popover-content .content .controls .btn-success:hover {
            color: #fff;
            background: linear-gradient(#83bd1b 0%, #4f792b 100%); }
        .popover.popup-crop-addTask .popover-content .content .controls .btn-cancel {
          color: #6e9e1f;
          background: none;
          box-shadow: none; }
          .popover.popup-crop-addTask .popover-content .content .controls .btn-cancel:focus, .popover.popup-crop-addTask .popover-content .content .controls .btn-cancel:hover {
            color: #507317; }

.modal-cropSettings .modal-dialog {
  width: 691px;
  height: 300px; }
  .modal-cropSettings .modal-dialog .modal-header,
  .modal-cropSettings .modal-dialog .modal-body {
    padding: 10px; }
  .modal-cropSettings .modal-dialog .modal-header {
    border-radius: 5px 5px 0 0;
    background: #f9fdfe; }
    .modal-cropSettings .modal-dialog .modal-header table {
      width: 100%; }
      .modal-cropSettings .modal-dialog .modal-header table td {
        vertical-align: middle; }
    .modal-cropSettings .modal-dialog .modal-header .modal-cropSettings-header-img {
      width: 52px;
      height: 52px; }
      .modal-cropSettings .modal-dialog .modal-header .modal-cropSettings-header-img img {
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid #c5c8c9;
        border-radius: 3px; }
    .modal-cropSettings .modal-dialog .modal-header .modal-cropSettings-header-name {
      width: 170px;
      padding-left: 15px;
      font-family: "Roboto-Medium";
      color: #7d7d7d; }
    .modal-cropSettings .modal-dialog .modal-header .modal-cropSettings-header-actions {
      text-align: right;
      font-size: 0; }
    .modal-cropSettings .modal-dialog .modal-header .btn {
      height: 30px;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      border-radius: 1px;
      text-align: center; }
      .modal-cropSettings .modal-dialog .modal-header .btn-success, .modal-cropSettings .modal-dialog .modal-header .btn-text {
        box-sizing: initial;
        padding: 0 10px; }
      .modal-cropSettings .modal-dialog .modal-header .btn-success {
        width: 120px;
        background: #4db141; }
        .modal-cropSettings .modal-dialog .modal-header .btn-success:focus, .modal-cropSettings .modal-dialog .modal-header .btn-success:hover, .modal-cropSettings .modal-dialog .modal-header .btn-success:active {
          background: #3d8c33; }
      .modal-cropSettings .modal-dialog .modal-header .btn-text {
        width: 95px;
        color: #4db141; }
        .modal-cropSettings .modal-dialog .modal-header .btn-text:focus, .modal-cropSettings .modal-dialog .modal-header .btn-text:hover, .modal-cropSettings .modal-dialog .modal-header .btn-text:active {
          outline: none;
          color: #3d8c33; }
  .modal-cropSettings .modal-dialog .modal-body > table {
    width: 100%; }
    .modal-cropSettings .modal-dialog .modal-body > table > tbody > tr > td {
      width: 50%;
      vertical-align: middle; }
      .modal-cropSettings .modal-dialog .modal-body > table > tbody > tr > td > table > tbody > tr > td {
        padding: 5px 0 5px 20px;
        vertical-align: middle; }
        .modal-cropSettings .modal-dialog .modal-body > table > tbody > tr > td > table > tbody > tr > td:first-child {
          text-align: right; }
  .modal-cropSettings .modal-dialog .modal-body .control-label {
    color: #9d9d9d;
    font-size: 14px; }
  .modal-cropSettings .modal-dialog .modal-body .form-control-static {
    line-height: 34px; }
  .modal-cropSettings .modal-dialog .modal-body .form-control {
    border-radius: 1px; }
    .modal-cropSettings .modal-dialog .modal-body .form-control[type="text"] {
      width: 110px; }
    .modal-cropSettings .modal-dialog .modal-body .form-control[type="number"] {
      width: 82px; }
  .modal-cropSettings .modal-dialog .modal-body .input-group-addon {
    margin: 0;
    padding: 0 0 0 6px;
    border: none;
    background: transparent;
    line-height: 0;
    cursor: pointer; }
    .modal-cropSettings .modal-dialog .modal-body .input-group-addon svg path {
      fill: #4db141; }
  .modal-cropSettings .modal-dialog .modal-body input[disabled] + .input-group-addon {
    cursor: not-allowed; }
    .modal-cropSettings .modal-dialog .modal-body input[disabled] + .input-group-addon svg path {
      fill: #eee; }
  .modal-cropSettings .modal-dialog .modal-footer {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 20px 10px; }
    .modal-cropSettings .modal-dialog .modal-footer .treshhold-link {
      display: flex;
      flex-basis: calc((100% - 20px) / 3);
      align-self: stretch;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      color: #1fab39; }
      .modal-cropSettings .modal-dialog .modal-footer .treshhold-link:first-child {
        border-right: thin solid #d8d8d8; }
      .modal-cropSettings .modal-dialog .modal-footer .treshhold-link:last-child {
        border-left: thin solid #d8d8d8; }

@media (min-width: 768px) {
  .modal-cropDelete .modal-dialog {
    width: 400px; } }

.modal-cropDelete .modal-content {
  background: rgba(255, 255, 255, 0.95); }

.modal-cropDelete .modal-header {
  padding: 0 30px;
  font-weight: 700;
  color: #4a4a4a;
  border-radius: 6px 6px 0 0;
  line-height: 56px;
  border-bottom: 2px solid #d5d5d5;
  background: #f9fdfe; }
  .modal-cropDelete .modal-header .modal-title {
    line-height: inherit; }
  .modal-cropDelete .modal-header .modal-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 30px;
    color: #4db141; }
    .modal-cropDelete .modal-header .modal-close:hover {
      color: #3d8c33; }

.f-panel-crops-crop-add {
  position: absolute;
  top: 10px;
  z-index: 3;
  height: 97px;
  padding-left: 10px;
  padding-right: 10px; }
  .f-panel-crops-crop-add div.f-panel-crops-crop-add-button {
    height: 100%;
    width: 100%;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    background: #4a92a6;
    transition: background-color .3s ease;
    text-align: center;
    padding-top: 14px;
    overflow: hidden; }
    .f-panel-crops-crop-add div.f-panel-crops-crop-add-button:hover {
      background: #428294; }
    .f-panel-crops-crop-add div.f-panel-crops-crop-add-button svg {
      width: 40px;
      height: 40px; }

.f-panel-crops-crop-label {
  width: 100%;
  padding-right: 0; }
  .f-panel-crops-crop-label ul li {
    float: left;
    overflow: hidden;
    *zoom: 1; }

.settings .f-w--spinner {
  position: fixed; }

.settings-account-edit span {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-left: 20px;
  line-height: normal;
  color: #669a1f !important;
  font-size: 12px;
  font-weight: 700; }
  .settings-account-edit span:before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    display: block;
    width: 18px;
    height: 19px;
    background: url(/img/edit_icon.22042016.png); }

.settings .btn {
  display: block;
  height: 34px;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  line-height: 34px;
  color: #fff;
  font-weight: 500; }
  .settings .btn-success {
    background: linear-gradient(to bottom, #9fe12b 0%, #69a039 100%); }
    .settings .btn-success:focus, .settings .btn-success:hover, .settings .btn-success:active {
      background: linear-gradient(to bottom, #83be1b 0%, #507a2c 100%); }
  .settings .btn-danger {
    background: linear-gradient(to bottom, #d95050 0%, #984949 100%); }
    .settings .btn-danger:focus, .settings .btn-danger:hover, .settings .btn-danger:active {
      background: linear-gradient(to bottom, #ca2c2c 0%, #763838 100%); }
  .settings .btn-warning {
    background: linear-gradient(to bottom, #f0ad4e 0%, #af8139 100%); }
    .settings .btn-warning:focus, .settings .btn-warning:hover, .settings .btn-warning:active {
      background: linear-gradient(to bottom, #ec971f 0%, #89652c 100%); }

.settings-header {
  overflow: hidden;
  position: relative;
  min-height: 64px;
  padding-right: 64px;
  border-bottom: 1px solid #e1e1e1;
  background: #f9fdfe; }
  .settings-header-account {
    float: left;
    line-height: 64px;
    margin-left: 15px; }
    @media (min-width: 768px) {
      .settings-header-account {
        margin-left: 100px; } }
    .settings-header-account span {
      margin-left: 5px;
      font-weight: 700;
      color: #073743;
      white-space: nowrap; }
  .settings-header-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #dceef5;
    background: #92b1bd;
    cursor: pointer; }
    .settings-header-close:hover {
      background: #82a6b3; }

.settings-wrapper {
  position: relative;
  height: 100%; }

.settings-left {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 215px;
  height: 100%;
  padding-top: 20px;
  padding-right: 35px;
  border-right: 1px solid #e1e1e1; }
  @media (min-width: 992px) {
    .settings-left {
      display: block; } }
  .settings-left-menu {
    padding: 0;
    list-style: none;
    text-align: right;
    font-weight: 700; }
    .settings-left-menu li a {
      display: block;
      padding: 10px 0;
      text-decoration: none;
      color: #669e37; }
      .settings-left-menu li a:hover {
        color: #4e782a; }

.settings-right {
  width: 100%;
  height: 100%; }
  @media (min-width: 992px) {
    .settings-right {
      padding-left: 215px; } }

.settings-section {
  overflow: hidden;
  *zoom: 1;
  width: 100%;
  padding: 35px 0 0 65px; }
  @media (max-width: 992px) {
    .settings-section {
      border-bottom: 1px solid #e1e1e1;
      padding: 20px; } }
  .settings-section-heading {
    overflow: hidden;
    *zoom: 1;
    float: left;
    padding-bottom: 8px;
    border-bottom: 1px solid #E1E1E1;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    color: #073743; }
    @media (min-width: 992px) {
      .settings-section-heading {
        margin: 5px 0 20px -20px;
        padding: 0;
        width: 180px;
        border: none; } }
  @media (min-width: 992px) {
    .settings-section-content {
      overflow: hidden;
      *zoom: 1;
      float: left; } }

.settings-section--users ul {
  margin-bottom: 20px;
  list-style: none; }
  .settings-section--users ul li {
    margin-bottom: 15px; }
    .settings-section--users ul li div {
      vertical-align: middle;
      display: inline-block; }

.settings-section--users .account td {
  vertical-align: middle;
  padding-bottom: 10px;
  white-space: nowrap; }
  .settings-section--users .account td + td {
    padding-left: 20px; }

.settings-section--users .account .account-avatar {
  position: relative;
  width: 64px;
  height: 60px;
  border-radius: 3px;
  background: no-repeat center;
  background-size: 64px 64px; }
  @media (max-width: 480px) {
    .settings-section--users .account .account-avatar {
      overflow: hidden;
      *zoom: 1;
      float: left; } }
  .settings-section--users .account .account-avatar .account-avatar-you {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16px;
    background: rgba(0, 0, 0, 0.4);
    font-size: 11px;
    color: #fff;
    text-align: center;
    line-height: 16px; }
  .settings-section--users .account .account-avatar .image-upload {
    position: absolute;
    display: inline-block;
    width: 34px;
    height: 34px;
    right: -17px;
    top: -8px;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out; }
    .settings-section--users .account .account-avatar .image-upload:hover {
      background: #f1f1f1;
      border-color: #d6d6d6; }
    .settings-section--users .account .account-avatar .image-upload:after {
      content: "\f040";
      font-family: 'FontAwesome';
      color: #757575;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto; }

.settings-section--users .account .account-info {
  width: 100%;
  padding-left: 20px;
  overflow: visible;
  align-self: center; }
  .settings-section--users .account .account-info .account-info-name {
    display: block;
    font-size: 16px; }
  .settings-section--users .account .account-info .account-info-email {
    display: block;
    font-size: 12px;
    color: #555; }
  @media (max-width: 480px) {
    .settings-section--users .account .account-info {
      overflow: hidden;
      *zoom: 1;
      float: left;
      align-self: initial;
      padding: 16px 0 16px 20px;
      width: calc(100% - 64px); } }

.settings-section--users .account .account-types .account-types-type {
  display: inline-block;
  width: auto;
  height: 24px;
  padding: 0 10px;
  border: 1px solid;
  border-radius: 4px;
  line-height: 22px;
  color: #fff;
  font-size: 13px; }
  .settings-section--users .account .account-types .account-types-type-ROLE_ACCOUNT_OWNER {
    background: #25606f;
    border-color: #2b7182; }
  .settings-section--users .account .account-types .account-types-type-ROLE_ACCOUNT_EDIT {
    background: #38a5d3;
    border-color: #4daed8; }
  .settings-section--users .account .account-types .account-types-type-user {
    background: #b3d82b;
    border-color: #bbdc41; }
  .settings-section--users .account .account-types .account-types-type-ROLE_ACCOUNT_VIEW {
    background: #acd87b;
    border-color: #b8de8f; }

.settings-section--users .account .account-actions.account-actions-invite {
  width: 100%;
  text-align: left;
  color: #f0f0f0; }
  .settings-section--users .account .account-actions.account-actions-invite span {
    cursor: pointer;
    padding: 4px 0;
    color: #669a1f;
    font-size: 14px;
    font-weight: 700; }

.settings-section--users .account.account-invitations .account-avatar:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(176, 169, 169, 0.5); }

.settings-section--users .account.account-invitations .account-info-email {
  color: #b0a9a9; }

.settings-section--users .account.account-invitations .account-info-name span {
  font-size: 14px;
  color: #b0a9a9; }

@media (min-width: 992px) {
  .settings-section--system {
    padding-bottom: 74px; } }

.settings-section--system label {
  color: #878787; }

.settings-section--system .form-control {
  margin-left: 10px; }

.settings-section--account .settings-section-content {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .settings-section--account .settings-section-content {
      max-width: calc(100% - 180px); } }
  @media (min-width: 768px) {
    .settings-section--account .settings-section-content {
      flex-direction: row; } }
  .settings-section--account .settings-section-content div {
    flex: 1;
    margin-bottom: 20px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E1E1E1; }
    @media (min-width: 768px) {
      .settings-section--account .settings-section-content div {
        max-width: 260px;
        margin: 0;
        padding: 0 40px 0 0;
        border: none; } }
    .settings-section--account .settings-section-content div h3 {
      font-weight: 700;
      font-size: 16px;
      color: #073743;
      margin-bottom: 20px; }
    .settings-section--account .settings-section-content div p {
      line-height: 1.3;
      margin-bottom: 15px; }

@media (min-width: 768px) {
  .modal-settings .modal .modal-dialog {
    width: 400px; } }

.modal-settings .modal .modal-content {
  background: rgba(255, 255, 255, 0.95); }

.modal-settings .modal .modal-header {
  height: 56px;
  padding: 0 30px;
  font-weight: 700;
  color: #4a4a4a;
  border-radius: 6px 6px 0 0;
  line-height: 56px;
  border-bottom: 2px solid #d5d5d5;
  background: #f9fdfe; }
  .modal-settings .modal .modal-header .modal-title {
    line-height: inherit; }
  .modal-settings .modal .modal-header .modal-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 30px;
    color: #4db141; }
    .modal-settings .modal .modal-header .modal-close:hover {
      color: #3d8c33; }

.modal-settings .modal .modal-body {
  padding: 30px 30px 20px; }
  .modal-settings .modal .modal-body .form-group label {
    margin-bottom: 8px;
    font-size: 12px;
    color: #6f6e6e; }
  .modal-settings .modal .modal-body .form-group.has-error label {
    color: #a94442; }
  .modal-settings .modal .modal-body .form-group .radio label {
    margin-bottom: 0;
    font-size: inherit;
    color: inherit; }
  .modal-settings .modal .modal-body .form-group .form-control {
    height: 36px;
    border: 1px solid #d5d5d5;
    border-radius: 1px; }

.modal-settings .modal .modal-footer {
  padding: 0 30px 30px;
  margin: 0;
  border: none;
  overflow: hidden; }
  .modal-settings .modal .modal-footer .btn {
    width: 123px;
    height: 35px;
    padding: 0;
    font-size: 16px;
    line-height: 35px;
    text-align: center; }
    .modal-settings .modal .modal-footer .btn-success {
      background: #4db141; }
      .modal-settings .modal .modal-footer .btn-success:focus, .modal-settings .modal .modal-footer .btn-success:hover, .modal-settings .modal .modal-footer .btn-success:active {
        background: #3d8c33; }
    .modal-settings .modal .modal-footer .btn-text {
      color: #4db141; }
      .modal-settings .modal .modal-footer .btn-text:focus, .modal-settings .modal .modal-footer .btn-text:hover, .modal-settings .modal .modal-footer .btn-text:active {
        outline: none; }

.rtable {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px; }
  .rtable .block-row {
    display: flex;
    padding: 10px;
    flex-basis: 100%; }
    @media (max-width: 480px) {
      .rtable .block-row {
        flex-direction: column; } }
    .rtable .block-row > div {
      align-self: center; }
    .rtable .block-row .account-data {
      display: flex;
      width: 100%; }
      @media (max-width: 480px) {
        .rtable .block-row .account-data {
          display: block; } }
    .rtable .block-row .data-info {
      flex-basis: calc(100% * 1.3 - 20px);
      overflow: visible; }
      @media (max-width: 768px) {
        .rtable .block-row .data-info {
          padding: 10px 0;
          flex-basis: auto; } }
      @media (max-width: 480px) {
        .rtable .block-row .data-info {
          margin-top: 5px;
          padding: 0;
          align-self: initial; } }
      .rtable .block-row .data-info .account-actions {
        display: inline-block;
        margin-left: 20px; }
        @media (max-width: 768px) {
          .rtable .block-row .data-info .account-actions {
            margin-left: 0;
            margin-top: 5px;
            width: 100%;
            text-align: center; } }
        @media (max-width: 480px) {
          .rtable .block-row .data-info .account-actions {
            margin-left: 20px;
            margin-top: 0;
            width: auto;
            text-align: initial; } }
      .rtable .block-row .data-info .account-types {
        display: inline-block; }
        @media (max-width: 768px) {
          .rtable .block-row .data-info .account-types {
            margin-top: 5px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap; } }
        @media (max-width: 480px) {
          .rtable .block-row .data-info .account-types {
            margin-top: 0;
            width: auto;
            text-overflow: initial;
            white-space: initial; } }
      .rtable .block-row .data-info .account-actions-invite {
        margin-left: 0; }
    .rtable .block-row .account-avatar {
      width: 100%;
      max-width: 64px;
      overflow: visible; }

.f-w {
  /* Rows */ }
  .f-w .c-grid--col:last-of-type {
    padding-right: 0; }
  .f-w .c-grid--row {
    /* Night background */ }
    .f-w .c-grid--row.f-w-weather {
      height: 20%; }
    .f-w .c-grid--row.f-w-wind {
      height: 10%; }
    .f-w .c-grid--row.f-w-tempChart {
      position: relative;
      height: 12%;
      font: 300 11px/1.4 "Roboto";
      z-index: 5; }
      .f-w .c-grid--row.f-w-tempChart svg {
        z-index: 15;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; }
    .f-w .c-grid--row.f-w-humidity {
      height: 4%; }
    .f-w .c-grid--row.f-w-evapo-trans {
      height: 13%; }
    .f-w .c-grid--row.f-w-temps {
      height: 25%; }
    .f-w .c-grid--row.f-w-plants {
      position: relative;
      height: 28%; }
      .f-w .c-grid--row.f-w-plants .f-w-temps {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15%; }
      .f-w .c-grid--row.f-w-plants .f-w-plant {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 10%;
        background: #b39852;
        z-index: 2; }
        .f-w .c-grid--row.f-w-plants .f-w-plant .plant-stage {
          font-size: 11px;
          position: absolute;
          bottom: 1px;
          width: 100%;
          display: inline-block;
          left: 2.5px;
          color: #3D3012; }
    .f-w .c-grid--row.f-w-precipitation {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 13%;
      width: 100%;
      background: linear-gradient(to bottom, #6d5720 0%, #755f23 100%); }
      .f-w .c-grid--row.f-w-precipitation > div {
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 100%; }
        .f-w .c-grid--row.f-w-precipitation > div svg {
          z-index: 15;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow: visible; }
        .f-w .c-grid--row.f-w-precipitation > div .nothing {
          pointer-events: none; }
          .f-w .c-grid--row.f-w-precipitation > div .nothing svg {
            height: 100%; }
    .f-w .c-grid--row.f-w-soil:hover ~ .f-w-soilMoisture .f-w-soilMoisture-info, .f-w .c-grid--row.f-w-precipitation:hover ~ .f-w-soilMoisture .f-w-soilMoisture-info {
      right: 0; }
    .f-w .c-grid--row.f-w-soil:hover ~ .f-w-soilMoisture .f-w-precipitation-info, .f-w .c-grid--row.f-w-precipitation:hover ~ .f-w-soilMoisture .f-w-precipitation-info {
      left: 0; }
    .f-w .c-grid--row.f-w-soilMoisture {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 13%;
      width: 100%; }
      .f-w .c-grid--row.f-w-soilMoisture[precipitations-selector] {
        user-select: none;
        cursor: pointer; }
        .f-w .c-grid--row.f-w-soilMoisture[precipitations-selector] #precipitations-selector {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 0;
          height: 100%;
          z-index: 5;
          background: rgba(156, 209, 242, 0.5); }
      .f-w .c-grid--row.f-w-soilMoisture .c-grid--col {
        z-index: auto; }
        .f-w .c-grid--row.f-w-soilMoisture .c-grid--col > div {
          width: 80px;
          height: 100%;
          margin: 0 auto;
          opacity: 0.5; }
          .f-w .c-grid--row.f-w-soilMoisture .c-grid--col > div > div {
            width: 100%;
            height: 25%; }
      .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-lines {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        text-align: right;
        z-index: 2;
        pointer-events: none; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-lines > div {
          height: 25%;
          border-bottom: 2px solid #887541; }
      .f-w .c-grid--row.f-w-soilMoisture:hover .f-w-soilMoisture-info {
        right: 0; }
      .f-w .c-grid--row.f-w-soilMoisture:hover .f-w-precipitation-info {
        left: 0; }
      .f-w .c-grid--row.f-w-soilMoisture .f-w-precipitation-info,
      .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-info {
        pointer-events: none;
        z-index: 37;
        position: absolute;
        bottom: 0; }
      .f-w .c-grid--row.f-w-soilMoisture .f-w-precipitation-info {
        left: 0;
        height: 125%;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        width: 100px; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-precipitation-info:before {
          content: attr(data-title);
          position: absolute;
          top: -5px;
          left: 0;
          font-size: 10px;
          white-space: nowrap;
          color: #fff; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-precipitation-info div {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 25%;
          text-align: center;
          font-size: 10px;
          color: #c0c9b8;
          border-bottom: 2px solid transparent; }
      .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-info {
        top: 0;
        right: 0;
        width: 40px;
        height: 100%; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-info:before {
          content: attr(data-title);
          position: absolute;
          top: -12px;
          left: 0;
          font-size: 10px;
          white-space: nowrap;
          color: #fff; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-info div {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 25%;
          text-align: center;
          font-size: 10px;
          color: #d4b462;
          border-bottom: 2px solid transparent; }
      .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors {
        position: absolute;
        top: 0;
        left: 150px;
        width: 50px;
        height: 100%;
        z-index: 10;
        border: 1px solid #fff;
        background: linear-gradient(to bottom, #6d5720 0%, #755f23 100%);
        transition: opacity .4s ease-in-out, visibility 0s linear .4s;
        opacity: 0;
        -webkit-transform: translateZ(0); }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors-show {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors:before {
          content: attr(data-title);
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 10px;
          white-space: nowrap;
          color: #fff; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 50%;
          left: -10px;
          margin-top: -10px;
          border: 10px solid transparent;
          border-right-color: #fff;
          border-left-width: 0; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text,
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .colors {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .colors {
          display: flex;
          flex-direction: column; }
          .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .colors div {
            display: flex;
            flex: 1;
            opacity: .5; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text div {
          position: absolute;
          width: 100%;
          line-height: 0;
          text-align: center;
          font-size: 10px;
          color: #fff;
          opacity: 0.9; }
          .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text div:nth-child(1) {
            top: 20%; }
          .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text div:nth-child(2) {
            top: 40%; }
          .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text div:nth-child(3) {
            top: 60%; }
          .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .text div:nth-child(4) {
            top: 80%; }
        .f-w .c-grid--row.f-w-soilMoisture .f-w-soilMoisture-colors .line {
          position: absolute;
          left: 5px;
          right: 5px;
          height: 1px;
          background: #fff; }
    .f-w .c-grid--row.f-w-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none; }
      .f-w .c-grid--row.f-w-background .is-dark {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); }
      .f-w .c-grid--row.f-w-background .is-rize {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.2) 100%); }
      .f-w .c-grid--row.f-w-background .is-set {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.2) 100%); }

w-weather {
  font: 300 11px/1.4 "Roboto"; }
  w-weather .w-weather {
    position: relative;
    text-align: center; }
    w-weather .w-weather--type {
      position: absolute;
      top: 22px;
      left: 0;
      right: 0;
      height: 58px; }
      w-weather .w-weather--type.is-empty {
        height: 55px; }
    w-weather .w-weather img {
      width: 75px;
      height: 75px; }
    w-weather .w-weather .w-weather--precip-prob {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 300;
      color: #3FA7E0;
      line-height: 20px;
      text-shadow: 0px 0px 3px #fff; }
      w-weather .w-weather .w-weather--precip-prob small {
        font-size: 11px;
        font-weight: 300; }
      w-weather .w-weather .w-weather--precip-prob.is-not-available {
        color: #6f8799; }
    w-weather .w-weather .w-weather--sunlight {
      position: absolute;
      top: 60px;
      left: 50%; }
      w-weather .w-weather .w-weather--sunlight img {
        position: absolute;
        left: -20px;
        top: -10px;
        width: 40px;
        height: 98px; }
    w-weather .w-weather .w-weather--solar {
      position: relative;
      top: 88px;
      display: inline-block;
      font-family: "Roboto";
      font-size: 16px;
      color: #F55E00;
      line-height: 24px;
      text-shadow: 0 0 2px #fff;
      text-align: center;
      border-radius: 5px; }
      w-weather .w-weather .w-weather--solar small {
        font-size: 11px;
        font-weight: 300; }
      w-weather .w-weather .w-weather--solar.is-not-available {
        color: #976849; }

w-wind .w-wind {
  font: 300 11px/1.4 "Roboto";
  text-align: center;
  width: 42%;
  margin: auto;
  position: relative; }
  w-wind .w-wind .w-wind--bg {
    width: 100%;
    height: auto;
    position: absolute; }
  w-wind .w-wind .w-wind--direction {
    width: 100%;
    height: auto;
    position: absolute; }
  w-wind .w-wind .w-wind--value {
    color: #3078ad;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 0 2px #fff;
    text-align: center;
    display: inline-block;
    width: 100%;
    line-height: 12px;
    padding-top: 35%;
    position: relative; }
    w-wind .w-wind .w-wind--value small {
      font-size: 11px;
      font-weight: 300;
      display: block;
      margin-top: 2px; }
    w-wind .w-wind .w-wind--value.is-not-available {
      line-height: normal;
      color: #6f8799; }

w-humidity {
  z-index: 5; }
  w-humidity .w-humidity {
    display: inline-block;
    padding: 3px 10px 2px 30px;
    font-size: 16px;
    font-weight: 500;
    color: #3078ad;
    line-height: 24px;
    text-shadow: 0 0 2px #fff;
    text-align: center;
    background: rgba(255, 255, 255, 0.7) no-repeat 5px 5px;
    background-size: 20px 21px;
    border-radius: 5px;
    margin-bottom: 13%;
    padding: 5px 10px 5px 30px;
    background-color: transparent;
    background-image: url("/img/weather/icon-humidity.22042016.png");
    background-position: 7px 6px; }
    w-humidity .w-humidity small {
      font-size: 11px;
      font-weight: 300; }
    w-humidity .w-humidity.is-not-available {
      background-image: url("/img/weather/icon-humidity-off.22042016.png");
      color: #6f8799; }

w-temps {
  z-index: 5; }
  w-temps .w-canopyTemp,
  w-temps .w-dewTemp,
  w-temps .w-leafWet {
    display: table;
    padding: 3px 16px 3px 32px;
    margin: 0 auto 14px;
    font-size: 15px;
    font-weight: 600;
    color: #3078ad;
    line-height: 24px;
    text-shadow: 0 1px 0 #fff;
    text-align: center;
    background: rgba(255, 255, 255, 0.7) no-repeat 5px 5px;
    background-size: 20px 21px;
    border-radius: 5px; }
    w-temps .w-canopyTemp small,
    w-temps .w-dewTemp small,
    w-temps .w-leafWet small {
      margin-left: 1px;
      font-size: 11px;
      font-weight: 600; }
    w-temps .w-canopyTemp.is-not-available,
    w-temps .w-dewTemp.is-not-available,
    w-temps .w-leafWet.is-not-available {
      color: #6f8799;
      background-color: rgba(111, 135, 153, 0.2); }
  w-temps .w-canopyTemp {
    margin-top: 7%;
    background-image: url("/img/weather/icon-canopy-temperature.22042016.png"); }
    w-temps .w-canopyTemp.is-not-available {
      background-image: url("/img/weather/icon-canopy-temperature-off.22042016.png"); }
  w-temps .w-dewTemp {
    background-image: url("/img/weather/icon-dew-point-temperature.22042016.png"); }
    w-temps .w-dewTemp.is-not-available {
      background-image: url("/img/weather/icon-dew-point-temperature-off.22042016.png"); }
  w-temps .w-leafWet {
    background-image: url("/img/weather/icon-leaf-wetness.22042016.png"); }
    w-temps .w-leafWet.is-not-available {
      background-image: url("/img/weather/icon-leaf-wetness-off.22042016.png"); }

w-evapo-trans {
  z-index: 5; }
  w-evapo-trans .w-evapo-trans {
    font: 300 11px/1.4 "Roboto";
    text-align: center; }
    w-evapo-trans .w-evapo-trans .w-evapo-trans--bg {
      position: absolute;
      left: 50%; }
      w-evapo-trans .w-evapo-trans .w-evapo-trans--bg .w-evapo-trans--bg--img {
        position: absolute;
        left: 20px;
        width: 29px;
        height: 96px; }
    w-evapo-trans .w-evapo-trans .w-evapo-trans--value {
      display: inline-block;
      width: 83px;
      padding-top: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #3078ad;
      line-height: 16px;
      text-align: center;
      text-shadow: 0 0 2px #fff; }
      w-evapo-trans .w-evapo-trans .w-evapo-trans--value small {
        font-size: 11px;
        font-weight: 300; }
      w-evapo-trans .w-evapo-trans .w-evapo-trans--value.is-not-available {
        color: #6f8799; }

w-plant img {
  position: absolute;
  bottom: 13px;
  display: block;
  max-width: 100%;
  height: auto;
  left: 2.5px;
  right: 0; }

w-soil {
  z-index: auto !important; }
  w-soil .w-soil-root {
    display: block;
    position: absolute;
    left: 5px;
    right: 0px;
    margin: 0 auto;
    max-width: 100%;
    z-index: 3;
    pointer-events: none; }
  w-soil .w-soil {
    display: inline-block;
    padding: 3px 10px 2px 30px;
    font-size: 16px;
    font-weight: 500;
    color: #3078ad;
    line-height: 24px;
    text-shadow: 0 0 2px #fff;
    text-align: center;
    background: rgba(255, 255, 255, 0.7) no-repeat 5px 5px;
    background-size: 20px 21px;
    border-radius: 5px;
    margin-bottom: 13%;
    position: relative;
    top: 0;
    padding: 5px 10px 5px 30px;
    color: #fff;
    text-shadow: none;
    background: url("/img/weather/icon-soil-temperature.22042016.png") no-repeat 7px 6px;
    background-size: 19px 19px;
    z-index: 3;
    pointer-events: none; }
    w-soil .w-soil small {
      font-size: 11px;
      font-weight: 300; }
    w-soil .w-soil.is-not-available {
      color: rgba(255, 255, 255, 0.7); }

.w-precipitation {
  background: #755f23; }

.w-precipitation-selector-popover {
  padding: 0 10px; }
  .w-precipitation-selector-popover-title {
    position: relative;
    padding-bottom: 7px;
    margin-bottom: 12px;
    color: #9b9b9b;
    font-size: 12px;
    text-align: center; }
    .w-precipitation-selector-popover-title:after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      width: 75%;
      height: 2px;
      margin: 0 auto;
      background: #f1f1f1; }
  .w-precipitation-selector-popover table {
    width: 100%; }
  .w-precipitation-selector-popover tr td:first-child {
    padding-bottom: 5px; }
  .w-precipitation-selector-popover tr td:first-child {
    padding-right: 5px;
    text-align: right;
    color: #25606f;
    font-size: 14px;
    font-family: "Roboto-Medium"; }
  .w-precipitation-selector-popover tr td:last-child {
    color: #666;
    font-size: 12px; }

.f-m {
  height: 100%;
  min-height: 632px;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .f-m .map-ndvi-switcher {
    height: 28px;
    width: 28px;
    border-radius: 2px;
    position: absolute;
    right: 10px;
    bottom: 205px;
    z-index: 2;
    background: url("/img/mapNdvi.01012019.png") no-repeat center #FFFFFF;
    cursor: pointer; }
    .f-m .map-ndvi-switcher.active {
      background-color: #4CB141; }
  .f-m .map-layer-switcher {
    height: 28px;
    width: 28px;
    border-radius: 2px;
    position: absolute;
    right: 10px;
    bottom: 165px;
    z-index: 2;
    background: url("/img/mapLayer.31102017.png") no-repeat center #FFFFFF;
    cursor: pointer; }
    .f-m .map-layer-switcher.active {
      background-color: #4CB141; }
  .f-m .map-dark-sky-attribution {
    height: 40px;
    width: 220px;
    position: absolute;
    right: 34px;
    bottom: 18px;
    z-index: 2;
    background: url("/img/poweredby-oneline-darkbackground.21092016.png") no-repeat;
    background-size: 220px; }
  .f-m.opened-thresholds {
    z-index: 3; }
  .f-m #gmap {
    display: block;
    width: 100%;
    height: 100%; }
    .f-m #gmap .field-label {
      z-index: 1000;
      position: absolute;
      padding: 8px 12px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.85);
      color: #888;
      font-size: 14px;
      white-space: nowrap;
      font-family: "Roboto-Light";
      opacity: 1; }
      .f-m #gmap .field-label .field-warning {
        top: -10px;
        right: -14px;
        height: 27px;
        width: 27px;
        position: absolute;
        display: none;
        background-image: url(/img/exMark.26082017.png); }
      .f-m #gmap .field-label:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 20px;
        width: 0;
        height: 0;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top-color: rgba(255, 255, 255, 0.85);
        border-bottom-width: 0; }
      .f-m #gmap .field-label.field-label--active {
        z-index: 1001; }
      .f-m #gmap .field-label .field-edit-window {
        padding: 0; }
        .f-m #gmap .field-label .field-edit-window label {
          display: block;
          padding-top: 7px; }
    .f-m #gmap .field-edit-link {
      display: inline;
      padding-left: 20px;
      padding-top: 2px;
      margin-left: 10px;
      background: url("/img/edit_icon.22042016.png") no-repeat;
      font-size: 12px;
      color: #669A1F;
      cursor: pointer; }
  .f-m .photonote-tooltip {
    position: absolute;
    height: 45px;
    width: 45px;
    background: rgba(51, 51, 51, 0.85);
    border-radius: 5px;
    margin-top: -71px;
    margin-left: -22px; }
    .f-m .photonote-tooltip:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 22px;
      width: 0;
      height: 0;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: rgba(51, 51, 51, 0.85);
      border-bottom-width: 0; }
    .f-m .photonote-tooltip .img-1 {
      border-radius: 5px;
      height: 33px;
      width: 33px;
      border: 1px solid rgba(255, 255, 255, 0.75);
      position: absolute;
      left: 6px;
      top: 6px;
      z-index: 10;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75); }
    .f-m .photonote-tooltip .img-2 {
      border-radius: 5px;
      height: 33px;
      width: 33px;
      border: 1px solid rgba(255, 255, 255, 0.75);
      position: absolute;
      left: 12px;
      top: 6px;
      z-index: 9;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75); }

@media (min-width: 768px) {
  .modal-cancelMapEdit .modal-dialog {
    width: 400px; } }

.modal-cancelMapEdit .modal-content {
  background: rgba(255, 255, 255, 0.95); }

.modal-cancelMapEdit .modal-header {
  padding: 0 30px;
  font-weight: 700;
  color: #4a4a4a;
  border-radius: 6px 6px 0 0;
  line-height: 56px;
  border-bottom: 2px solid #d5d5d5;
  background: #f9fdfe; }
  .modal-cancelMapEdit .modal-header .modal-title {
    line-height: inherit; }
  .modal-cancelMapEdit .modal-header .modal-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 30px;
    color: #4db141; }
    .modal-cancelMapEdit .modal-header .modal-close:hover {
      color: #3d8c33; }

.f-edit-map-tools {
  position: absolute;
  z-index: 1000;
  background: black;
  background: rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  left: 16px;
  top: 73px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 0 10px 10px 0; }
  .f-edit-map-tools .f-edit-map-tools--tool {
    margin-left: 18px;
    margin-right: 29px;
    margin-top: 2px;
    height: 42px;
    width: 42px;
    border-radius: 2px;
    display: block;
    background-size: cover; }
  .f-edit-map-tools .f-edit-map-tools--save {
    margin-top: 26px;
    margin-left: 4px; }
  .f-edit-map-tools .f-edit-map-tools--cancel {
    display: block;
    font-size: 13px;
    color: white;
    margin-top: 14px;
    margin-left: 20px; }
  .f-edit-map-tools .poly {
    background-image: url("/img/poly.22042016.png"); }
    .f-edit-map-tools .poly.is-active {
      background-image: url("/img/poly_active.22042016.png"); }
  .f-edit-map-tools .circle {
    background-image: url("/img/circle.22042016.png"); }
    .f-edit-map-tools .circle.is-active {
      background-image: url("/img/circle_active.22042016.png"); }
  .f-edit-map-tools .hand {
    background-image: url("/img/hand.22042016.png"); }
    .f-edit-map-tools .hand.is-active {
      background-image: url("/img/hand_active.22042016.png"); }
  .f-edit-map-tools .active {
    border: 2px solid #a0e22a; }

.f-edit-map-bottom {
  position: absolute;
  z-index: 1000;
  background: black;
  background: rgba(0, 0, 0, 0.3);
  top: 100%;
  left: -16px;
  height: 16px;
  margin-top: -16px;
  width: 100%; }

.f-edit-map-right {
  position: absolute;
  z-index: 1000;
  background: black;
  background: rgba(0, 0, 0, 0.3);
  top: 16px;
  left: 100%;
  height: 100%;
  margin-left: -16px;
  width: 16px; }

.f-edit-map-top {
  position: absolute;
  z-index: 1000;
  background: black;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 16px;
  height: 16px;
  width: 100%; }

.f-edit-map-left {
  position: absolute;
  z-index: 1000;
  background: black;
  background: rgba(0, 0, 0, 0.3);
  top: -16px;
  left: 0;
  height: 100%;
  width: 16px; }

.field-edit-window h1 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold; }

.field-edit-window .field--name {
  width: 150px; }

.field-edit-window .ui-select-container {
  width: 165px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer; }

.field-edit-window .close {
  float: right;
  font-size: 12px; }

.field-edit-window .field-edit-window--save {
  float: left;
  margin-right: 8px; }

.field-edit-window .field-edit-window--button {
  display: block;
  font-size: 10px;
  font-family: "Roboto-Medium";
  background: rgba(0, 0, 0, 0) linear-gradient(-180deg, #a0e22a 0%, #679f38 100%) repeat scroll 0 0;
  border-radius: 2px;
  color: white;
  padding: 4px 10px;
  margin-top: 10px;
  margin-bottom: 0;
  margin-right: 8px;
  float: left; }

.field-edit-window .field-edit-window--button:disabled {
  background: rgba(0, 0, 0, 0) linear-gradient(-180deg, #cccccc 0%, #999999 100%) repeat scroll 0 0; }

.field-edit-window .field-edit-window--delete {
  float: right;
  color: #F56423;
  margin-top: 20px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none; }

html {
  height: 100%; }

body {
  min-height: 100%;
  overflow-x: hidden; }

.l-content {
  height: 100%; }

.f {
  position: relative;
  height: calc(100vh - 218px); }

.f-view-switcher {
  position: absolute;
  top: 100%;
  left: 48px;
  cursor: pointer;
  margin-left: -34px;
  margin-top: -34px;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 2px solid white;
  background: no-repeat 50% 50%; }
  .f-view-switcher.is-w {
    background-image: url("/img/b2.22042016.png"); }
  .f-view-switcher.is-m {
    background-image: url("/img/b1.22042016.png"); }
  .f-view-switcher.is-map-edit {
    background-image: url("/img/b3.22042016.png");
    cursor: default;
    pointer-events: none; }

.f-w {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 632px;
  background: linear-gradient(to top, #e9f3f8, #82d0f8);
  text-align: center; }
  .f-w .c-grid--row {
    height: 15%; }
    .f-w .c-grid--row.is-hideable {
      visibility: hidden; }
    .f-w .c-grid--row.is-active {
      visibility: visible; }
    .f-w .c-grid--row .is-hideable {
      visibility: hidden; }
    .f-w .c-grid--row .is-active {
      visibility: visible; }
  .f-w .c-grid--col {
    position: relative;
    z-index: 3;
    height: 100%; }

.f-w--spinner {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center; }
  .f-w--spinner rect {
    animation: fadeIn 1s infinite; }
    .f-w--spinner rect:nth-child(1) {
      animation-delay: 0s; }
    .f-w--spinner rect:nth-child(2) {
      animation-delay: 0.08333333333333333s; }
    .f-w--spinner rect:nth-child(3) {
      animation-delay: 0.16666666666666666s; }
    .f-w--spinner rect:nth-child(4) {
      animation-delay: 0.25s; }
    .f-w--spinner rect:nth-child(5) {
      animation-delay: 0.3333333333333333s; }
    .f-w--spinner rect:nth-child(6) {
      animation-delay: 0.4166666666666667s; }
    .f-w--spinner rect:nth-child(7) {
      animation-delay: 0.5s; }
    .f-w--spinner rect:nth-child(8) {
      animation-delay: 0.5833333333333334s; }
    .f-w--spinner rect:nth-child(9) {
      animation-delay: 0.6666666666666666s; }
    .f-w--spinner rect:nth-child(10) {
      animation-delay: 0.75s; }
    .f-w--spinner rect:nth-child(11) {
      animation-delay: 0.8333333333333334s; }
    .f-w--spinner rect:nth-child(12) {
      animation-delay: 0.9166666666666666s; }

.f-w--blackout,
.f-m--blackout {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6; }
  .f-w--blackout-animated.ng-enter, .f-w--blackout-animated.ng-leave,
  .f-m--blackout-animated.ng-enter,
  .f-m--blackout-animated.ng-leave {
    animation-duration: .3s; }
  .f-w--blackout-animated.ng-enter .f-w--blackout,
  .f-w--blackout-animated.ng-leave .f-w--blackout,
  .f-m--blackout-animated.ng-enter .f-w--blackout,
  .f-m--blackout-animated.ng-leave .f-w--blackout {
    animation-duration: .3s;
    animation-fill-mode: both; }
  .f-w--blackout-animated.ng-enter .f-w--blackout,
  .f-w--blackout-animated.ng-leave.ng-leave-active .f-w--blackout,
  .f-m--blackout-animated.ng-enter .f-w--blackout,
  .f-m--blackout-animated.ng-leave.ng-leave-active .f-w--blackout {
    animation-name: fadeOut; }
  .f-w--blackout-animated.ng-leave .f-w--blackout,
  .f-w--blackout-animated.ng-enter.ng-enter-active .f-w--blackout,
  .f-m--blackout-animated.ng-leave .f-w--blackout,
  .f-m--blackout-animated.ng-enter.ng-enter-active .f-w--blackout {
    animation-name: fadeIn; }

c-blackout.is-active-thresholds .f-w--blackout {
  z-index: 1000; }

.blackout-ww {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5); }
  .blackout-ww-animated.ng-enter, .blackout-ww-animated.ng-leave {
    animation-duration: .3s; }
  .blackout-ww-animated.ng-enter .f-w--blackout,
  .blackout-ww-animated.ng-leave .f-w--blackout {
    animation-duration: .3s;
    animation-fill-mode: both; }
  .blackout-ww-animated.ng-enter .f-w--blackout,
  .blackout-ww-animated.ng-leave.ng-leave-active .f-w--blackout {
    animation-name: fadeOut; }
  .blackout-ww-animated.ng-leave .f-w--blackout,
  .blackout-ww-animated.ng-enter.ng-enter-active .f-w--blackout {
    animation-name: fadeIn; }

.f-footer {
  position: relative;
  background: #494f48; }
